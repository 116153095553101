import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useSelector, useDispatch, connect } from 'react-redux'
import { } from "../../data/actions/product.actions";
import { createProduct, getAllProducts } from "../../data/reducers/productx.reducer";
import { getAllDropdowns, addNewEquipmentCategory, addNewModelOutput } from "../../data/actions/configuration-data.actions";

import SingleInputModal from "../../shared/components/single-input-modal/SingleInputModal";
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import { withLoader } from '../../utils/hoc/withLoader'
import { errorMessage } from '../../data/reducers/alerts.reducer'

const AddProduct = ({ operationInProgress, actions, dropdowns, setBusy }) => {

    const history = useHistory()
    const dispatch = useDispatch();
    const [product, setProduct] = useState({})
    const [formValidated, setFormValidated] = useState(false);

    // useEffect(() => {
    //     // executes when new product / equipment is created
    //     if (createdProduct !== undefined) {
    //         // refresh product info
    //         actions.getAllProducts()

    //         // navigate to products dashboard page
    //         history.push('/products')
    //     }
    // }, [createdProduct])

    useEffect(() => {
        if (dropdowns === undefined) {
            actions.getAllDropdowns()
        }
    }, [dropdowns])

    const [newEquipmentDialogVisible, setNewEquipmentDialogVisible] = useState(false)
    const [newModelOutputDialogVisible, setNewModelOutputDialogVisible] = useState(false)
    const [newEquipmentCategory, setNewEquipmentCategory] = useState();

    const onAddEquipmnetCategory = async (value) => {
        try {
            setBusy(true);
            var response = await actions.addNewEquipmentCategory(value);
            if (response.id){
                setProduct({ ...product, equipmentCategory: parseInt(response.id) });
            }
            else {
                dispatch(errorMessage(response));
            }
        } catch (e) {
            console.log(e)
        }
        finally {
            setBusy(false);
            setFormValidated(false);
        }
    }

    const onAddModelOutput = async (value) => {
        try{
            setBusy(true);
            var response = await actions.addNewModelOutput(value);
            if (response.id){
                setProduct({ ...product, modelOutput: parseInt(response.id) });
            }
            else {
                dispatch(errorMessage(response));
            }
        } catch (e) {
            console.log(e);
        }
        finally {
            setBusy(false);
            setFormValidated(false);
        }
    }

    const validateAndSubmitForm = (e) => {
        const isValid = e.currentTarget.checkValidity();
        e.preventDefault();
        e.stopPropagation();
        if (isValid) {
            onSubmitProductForm();
        } else {
            setFormValidated(true);
        }
    };

    const onSubmitProductForm = async () => {
        try {
            let response = await dispatch(createProduct(product));
            if (response.error) {

            } else {
                dispatch(getAllProducts());
                history.push('/products');
            }
        } catch (e) {
            console.log(e);
        }
    };
    // setTimeout(function () {
    //     window.location.reload();
    // }, 5000);
    return (
        <Form noValidate validated={formValidated} onSubmit={(e) => validateAndSubmitForm(e)}>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item"><Link to='/products'>Equipments</Link></li>
                <li className="breadcrumb-item active">Add New Equipment</li>
            </ol>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>Add New Equipment</h2>
                    <div>Fill up equipment category and model/output information to create new equipment listing</div>
                    <br />
                    <div className='card'>
                        <div className='card-body'>
                            <div className='container-fluid form'>
                                <section className='my-3 row'>
                                    <div className='col-md-12'>
                                        <h4>Basic Equipment Details</h4>
                                        <p>Equipment category and model / output information</p>
                                    </div>
                                    <div className='col-md-12 row'>
                                        <div className="col-md-4">
                                            <Form.Group>
                                                <Form.Label>Equipment Category</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product?.equipmentCategory}
                                                    name="equipmentCategory"
                                                    onChange={(e) => { setProduct({ ...product, equipmentCategory: parseInt(e.target.value) }) }}
                                                    required
                                                >
                                                    <option key="0" value="">--- Select ---</option>
                                                    {dropdowns?.masterEquipmentCategory?.map(equipmentCategory => (
                                                        <option key={equipmentCategory.id} value={equipmentCategory.id}>{equipmentCategory.equipmentCategory}</option>
                                                    ))}
                                                </Form.Control>
                                                <a href="#" className='align-self-end' onClick={(e) => { setNewEquipmentDialogVisible(true) }}>add new</a>
                                                <Form.Control.Feedback type="invalid">
                                                    Please specify an equipment category.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {/* <div className="form-group d-flex flex-column">
                                                <label>Equipment Category</label>

                                                <select className='form-control' onChange={(e) => { setProduct({ ...product, equipmentCategory: parseInt(e.target.value) }) }}>
                                                    <option key="0" value="">--- Select ---</option>
                                                    {dropdowns?.masterEquipmentCategory?.map(equipmentCategory => (
                                                        <option key={equipmentCategory.id} value={equipmentCategory.id}>{equipmentCategory.equipmentCategory}</option>
                                                    ))}
                                                </select>
                                                <a href="#" className='align-self-end' onClick={(e) => { setNewEquipmentDialogVisible(true) }}>add new</a>
                                            </div> */}
                                        </div>

                                        <div className="col-md-4 offset-md-1">
                                            <Form.Group>
                                                <Form.Label>Model / Output</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={product?.modelOutput}
                                                    name="modelOutput"
                                                    onChange={(e) => { setProduct({ ...product, modelOutput: parseInt(e.target.value) }) }}
                                                    required
                                                >
                                                    <option key="0" value="">--- Select ---</option>
                                                    {dropdowns?.masterModelOutputCategory?.map(modelOutput => (
                                                        <>
                                                            <option key={modelOutput.id} value={modelOutput.id}>{modelOutput.modelOutput}</option>
                                                        </>
                                                    ))}
                                                </Form.Control>
                                                <a href="#" className='align-self-end' onClick={(e) => { setNewModelOutputDialogVisible(true) }}>add new</a>
                                                <Form.Control.Feedback type="invalid">
                                                    Please specify a model / output.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {/* <div className="form-group d-flex flex-column">
                                                <label>Model / Output</label>
                                                <select className='form-control' onChange={(e) => { setProduct({ ...product, modelOutput: parseInt(e.target.value) }) }}>
                                                    <option key="0" value="">--- Select ---</option>
                                                    {dropdowns?.masterModelOutputCategory?.map(modelOutput => (
                                                        <option key={modelOutput.id} value={modelOutput.id}>{modelOutput.modelOutput}</option>
                                                    ))}
                                                </select>
                                                <a href="#" className='align-self-end' onClick={(e) => { setNewModelOutputDialogVisible(true) }}>add new</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 my-3'>
                    <button className='btn btn-primary' variant="submit">Create</button>
                    &nbsp;&nbsp;
                    <button className='btn btn-danger' onClick={(e) => { e.preventDefault(); history.push('/products'); }}>Discard</button>
                </div>
                <SingleInputModal
                    modalTitle='Add New Equipment Category'
                    inputFieldLabel='Equipment Category'
                    placeholder='Name of Equipment Category'
                    isVisible={newEquipmentDialogVisible}
                    onSubmit={(value) => { setNewEquipmentDialogVisible(false); onAddEquipmnetCategory(value) }}
                    onCancel={() => { setNewEquipmentDialogVisible(false) }} />
                <SingleInputModal
                    modalTitle='Add New Model / Output'
                    inputFieldLabel='Model / Output'
                    placeholder='Model / Output'
                    isVisible={newModelOutputDialogVisible}
                    onSubmit={(value) => { setNewModelOutputDialogVisible(false); onAddModelOutput(value); }}
                    onCancel={() => { setNewModelOutputDialogVisible(false) }} />
            </div>
        </Form>
    )
}
const mapStateToProps = (globalState) => ({
    operationInProgress: globalState.productReducer.loading,
    dropdowns: globalState.configurationReducer.dropdowns
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getAllProducts, getAllDropdowns, addNewEquipmentCategory, addNewModelOutput }, dispatch)
})

export default withLoader(connect(mapStateToProps, mapDispatchToProps)(AddProduct))