import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { getAllProducts } from "../../data/reducers/productx.reducer"
import { deleteEquipment, addNewAccessory, setEquipmentId, setEquipmentPrice, setEquipmentQuantity, toggleEquipmentSelection } from "../../data/reducers/quote.reducer";

import AccessoryCard from "./AccessoryCard"
import BulkAccessoryAddModal from './BulkAccessoryAddModal'

const SingleEquipmentCard = ({ equipment }) => {

    const dispatch = useDispatch()

    const products = useSelector(state => state.productsXReducer)?.products
    const equipmentGroups = products ? getEquipmentGroupsFromEquipmentList(products) : {}

    const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState(undefined)
    const [filteredEquipmentList, setFilteredEquipmentList] = useState([]);

    const fetchProducts = async () => {
        if (products === undefined) {
            try { dispatch(getAllProducts()) }
            catch (e) { console.log(e) }
        }
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    // used for reverse mapping when 
    // older quote is loaded
    useEffect(() => {
        if (selectedEquipmentCategory === undefined && equipment.equipmentId !== undefined) {
            // find equipment category for selected equipment id
            let product = products?.find(product => product.id === equipment.equipmentId)
            if (product) {
                setSelectedEquipmentCategory(product.equipmentCategoryName)
            }
        }
    }, [equipment.equipmentId, selectedEquipmentCategory, products])

    // set filtered list of product according
    // to the equipment category selected
    useEffect(() => {
        if (selectedEquipmentCategory !== undefined) {
            let list = products?.filter(product => product.equipmentCategoryName === selectedEquipmentCategory);
            setFilteredEquipmentList(list);

            // // automatically select first equipment
            // if (list.length > 0 && equipment.equipmentId === undefined) {
            //     let selectedEquipment = list[0];
            //     dispatch(setEquipmentId({ id: equipment.id, equipmentId: selectedEquipment.id }));
            //     dispatch(setEquipmentPrice({ id: equipment.id, price: selectedEquipment.seasonal }));
            // }
        }
    }, [selectedEquipmentCategory, products, dispatch, equipment.id])

    useEffect(() => {
        setEquipmentPrice(products?.find(product => product.id === equipment.equipmentId)?.seasonal)
    }, [equipment.equipmentId, products])

    // accessories
    const { draftQuote } = useSelector(state => state.quoteReducer)
    const accessories = draftQuote?.lstQuoteAccessories?.filter(accessory => accessory.parentId === equipment.identifier)

    const [bulkAddModalVisible, setBulkAddModalVisible] = useState(false)

    return (
        <>
            <tr>
                <td style={{ verticalAlign: 'middle' }}><input type='checkbox' checked={equipment?.selected} onChange={(e) => dispatch(toggleEquipmentSelection({ id: equipment.id, checked: e.target.checked }))} /></td>
                <td>
                    <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                            setSelectedEquipmentCategory(e.target.value)
                        }}
                        value={selectedEquipmentCategory}>
                        <option value='0'>---Select---</option>
                        {Object.keys(equipmentGroups).map(equipmentCategory => (
                            <option key={equipmentCategory} value={equipmentCategory}>{equipmentCategory}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                            let selectedProduct = products.find(product => product.id === parseInt(e.target.value))
                            dispatch(setEquipmentId({ id: equipment.id, equipmentId: selectedProduct.id }))
                            dispatch(setEquipmentPrice({ id: equipment.id, price: selectedProduct.seasonal }))
                        }}
                        value={equipment.equipmentId}>
                        <option value='0'>---Select---</option>
                        {filteredEquipmentList?.map(product => (
                            <option key={product.id} value={product.id}>{product.modelOutputname}</option>
                        ))}
                    </select>
                </td>
                <td></td>
                <td></td>
                <td>
                    <input className="form-control form-control-sm" type='number' value={equipment.quantity} onChange={(e) => { dispatch(setEquipmentQuantity({ id: equipment.id, quantity: parseInt(e.target.value) })) }} placeholder='Qty'></input>
                </td>
                <td>
                    <input className="form-control form-control-sm" type='number' value={equipment.price} onChange={(e) => { dispatch(setEquipmentPrice({ id: equipment.id, price: parseFloat(e.target.value) })) }} placeholder='Price'></input>
                </td>
                <td>
                    <input className="form-control form-control-sm" type='text' placeholder='Total' value={equipment.total} readOnly></input>
                </td>
                <td>
                    <button
                        className='btn text-danger'
                        onClick={(e) => {
                            dispatch(deleteEquipment({ id: equipment.id, equipmentId: equipment.equipmentId }))
                        }}>
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
            {accessories?.map(accessory => (
                <AccessoryCard key={accessory.id} accessory={accessory} />
            ))}
            <tr style={{ borderBottom: '2px solid #888' }}>
                <td colSpan='3' style={{ paddingBottom: '1.2rem', paddingTop: '1rem' }}>
                    {/* <button className='btn btn-primary btn-sm' onClick={(e) => { dispatch(addNewAccessory({ equipmentId: equipment.equipmentId, parentId: equipment.identifier })) }}>Add Accessory</button> */}
                    &nbsp;
                    <button className='btn btn-primary btn-sm' onClick={(e) => { setBulkAddModalVisible(true) }}>Add Accessory</button>
                </td>
                <td colSpan='6'>&nbsp;</td>
            </tr>
            <BulkAccessoryAddModal equipmentId={equipment.equipmentId} identifier={equipment.identifier} isVisible={bulkAddModalVisible} hideModal={() => { setBulkAddModalVisible(false) }} />
        </>
    )
}

export default SingleEquipmentCard

export const getEquipmentGroupsFromEquipmentList = (equipmentList) => {

    let equipmentGroups = {}

    equipmentList.forEach(equipment => {
        if (equipmentGroups[equipment.equipmentCategoryName] === undefined) {
            equipmentGroups[equipment.equipmentCategoryName] = []
        }

        equipmentGroups[equipment.equipmentCategoryName].push(equipment)
    })

    return equipmentGroups
}