import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { withLoader } from "../../utils/hoc/withLoader";

import DataTable from "react-data-table-component";

import { getCustomers } from "../../data/reducers/customerx.reducer";
import {
	deleteCustomer,
	getCustomerById,
} from "../../data/reducers/customerx.reducer";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";

const CustomersDashboard = ({ setBusy }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { customers } = useSelector((state) => state.customerXReducer);
	const { selectedCustomer } = useSelector((state) => state.customerXReducer);

	const [customerList, setCustomerList] = useState(undefined);
	const [customerLocal, setCustomer] = useState(null);

	useEffect(() => {
		dispatch(getCustomers());
	}, []);

	useEffect(() => {
		setCustomerList(customers);
	}, [customers]);

	useEffect(() => {
		setCustomer(selectedCustomer);
	}, [selectedCustomer]);
	const [searchQuery, setSearchQuery] = useState("");

	const tableColumns = [
		{ name: "ID", selector: "customerDetails.id" },
		{ name: "Name", selector: "customerDetails.name", sortable: true },
		{
			name: "Create Quote",
			cell: (row) => (
				<div className="create-quote">
					<Link to={"/quote/add?customerId=" + row.customerDetails.id}>
						Create Quote
					</Link>
				</div>
			),
		},
		{
			name: "Actions",
			cell: (row) => (
				<div className="action-items">
					<Link
						to={"/customers/edit?customerId=" + row.customerDetails.id}
						className="btn btn-outline btn-sm m-1"
					>
						<i className="far fa-edit"></i>
					</Link>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={() => {
							del(row.customerDetails.id);
						}}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];
	const del = async (id) => {
		try {
			setBusy(true);
			await dispatch(deleteCustomer(id));
			await dispatch(getCustomers());
		} finally {
			setBusy(false);
		}
	};
	const getFilteredCustomer = () => {
		let query = searchQuery.toLowerCase();
		let filteredQuotes = customerList;

		// if query is not empty
		// filter by query

		if (query !== "") {
			filteredQuotes = customerList.filter(
				(customer) =>
					customer?.customerDetails?.name?.toLowerCase().includes(query) ||

					customer?.customerDetails?.id?.toString().toLowerCase().includes(query)
			);
		}


		return filteredQuotes;
	};
	const customerData = (
		<div className="card" style={{ marginTop: "1rem" }}>
			<div className="card-body">
				<div className="row">
					<div className="col-md-6">
						<h4>Customer List</h4>
					</div>
					<div className="offset-md-4 col-md-2 text-right">
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={(e) => {
								setSearchQuery(e.target.value);
							}}
						></input>
					</div>
					<div className="col-md-12">
						<DataTable
							noHeader={true}
							columns={tableColumns}
							data={
								// searchQuery == ""
								// 	? customerList
								// 	: customerList.filter((customer) =>
								// 		customer.customerDetails.name
								// 			.toLowerCase()
								// 			.includes(searchQuery.toLowerCase())
								// 	) ||
								// 	customerList.filter((customer) =>
								// 		customer.customerDetails.id
								// 			.toLowerCase()
								// 			.includes(searchQuery.toLowerCase())
								// 	)
								getFilteredCustomer()

							}
							pagination={true}
							paginationPerPage={100}
							paginationRowsPerPageOptions={[10, 50, 100, 200]}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item active">Customers</li>
			</ol>
			<div className="row">
				<div className="col-md-8">
					<h4>All Customers</h4>
				</div>
				<div className="col-md-4 text-right">
					<button
						className="btn btn-primary"
						onClick={() => {
							history.push("/customers/add");
						}}
					>
						Add New Customer
					</button>
				</div>
				<div className="col-md-12">
					{customerList !== undefined ? (
						customerData
					) : (
						<div>No customers available</div>
					)}
				</div>
			</div>
		</>
	);
};

export default withLoader(CustomersDashboard);
