import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import {
	createAddressContact,
	getCustomers,
	getCustomerById,
} from "../../data/reducers/customerx.reducer";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";
import { createAddress } from "../../data/reducers/customerx.reducer";
const AddAddressContact = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const customerId = queryString.parse(location.search).customerId;
	const shouldGoBack = queryString.parse(location.search).goBack;
	console.log("custmore", customerId);

	const [customer, setCustomer] = useState({
		contactAndAddressDetails: {},
	});

	const create = async (e) => {
		try {
			e.preventDefault();
			let response = await dispatch(
				createAddress({
					customerId,
					...customer.contactAndAddressDetails,
					// isDefaultBilling: true
				})
			);

			if (response.error) {
				console.log(response.error);
			} else {
				if (shouldGoBack) {
					history.goBack();
				} else {
					history.push("/customers/edit?customerId=" + customerId);
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			{/* <AddAddressContact /> */}
			<div className="card mt-4">
				<div className="card-body">
					<h4>Customer Contact and Address Details</h4>
					<p>Basic Details</p>
					<form className="row form" onSubmit={create}>
						<div className="col-md-6">
							<label>First Name</label>
							<input
								required
								className="form-control"
								placeholder="First Name"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											firstName: e.target.value,
										},
									});
								}}
							/>
							<br />
							<label>Last Name</label>
							<input
								required
								className="form-control"
								placeholder="Last Name"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											lastName: e.target.value,
										},
									});
								}}
							/>

							<br />
							<p>Address Details</p>
							<label>Address Line 1</label>
							<input
								required
								className="form-control"
								placeholder="Address"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											addressLine1: e.target.value,
										},
									});
								}}
							/>
							<br />
							<label>Address Line 2</label>
							<input
								className="form-control"
								placeholder="Address"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											addressLine2: e.target.value,
										},
									});
								}}
							/>
							<br />
							<label>City</label>
							<input
								required
								className="form-control"
								placeholder="City"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											city: e.target.value,
										},
									});
								}}
							/>
							<br />
						</div>
						<div className="col-md-6">
							<label>Phone</label>
							<input
								required
								className="form-control"
								placeholder="Phone"
								type="number"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											phone: e.target.value,
										},
									});
								}}
							/>
							<br />
							<label>Email</label>
							<input
								required
								className="form-control"
								placeholder="Email"
								type="email"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											email: e.target.value,
										},
									});
								}}
							/>
							<br />
							<br />
							<label className="mt-3">State</label>
							<input
								className="form-control"
								placeholder="State"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											state: e.target.value,
										},
									});
								}}
							/>
							<br />
							<label>Zip Code</label>
							<input
								required
								className="form-control"
								placeholder="Zip Code"
								type="number"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											zipcode: e.target.value,
										},
									});
								}}
							/>
							<br />
							<label>Note</label>
							<input
								className="form-control"
								placeholder="Note"
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											notes: e.target.value,
										},
									});
								}}
							/>
						</div>
						<div className="mt-3">
							<button className="btn btn-primary mr-2">Save</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddAddressContact;
