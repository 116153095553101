import React, { useEffect } from "react";
import { Route, Redirect, Switch, useHistory, NavLink } from "react-router-dom";

import Header from "../shared/components/header/Header";
import MenuBar from "./menu";

// quotes
import QuoteDashboard from "../pages/quotes/QuoteDashboard";
import AddQuote from "../pages/quotes/AddQuote";

//Archived Quotes
import ArchivedQuotes from "../pages/quotes/ArchivedQuotes";

//Draft Quotes
import DraftQuotes from "../pages/quotes/DraftQuotes";

// products
import ProductsDashboard from "../pages/products/ProductsDashboard";
import AddProduct from "../pages/products/AddProduct";
import EditProduct from "../pages/products/EditProduct";

// accessories
import AccessoriesDashboard from "../pages/accessories/AccessoriesDashboard";
import AddAccessory from "../pages/accessories/AddAccessory";
import EditAccessory from "../pages/accessories/EditAccessory";

// customers
import CustomersDashboard from "../pages/customers/CustomersDashboard";
import AddCustomer from "../pages/customers/AddCustomer";
import EditCustomer from "../pages/customers/EditCustomer";

import AddAddress from "../pages/customers/AddAddress";
import EditAddress from "../pages/customers/EditAddress";

import AddContact from "../pages/customers/AddContact";
import EditContact from "../pages/customers/EditContact";

import AddAddressContact from "../pages/customers/AddAddressContact";
// users
import UsersDashboard from "../pages/users/UsersDashboard";
import AddUser from "../pages/users/AddUser";
import EditUser from "../pages/users/EditUser";

// email
import MailSettings from "../pages/settings/MailSettings";

//Notes
import Notes from "../pages/Notes/Notes";
import AddNotes from "../pages/Notes/AddNotes";
import "./dashboard.routes.scss";
import Sidebar from "./sidebar";

const DashboardRoutes = () => {
	const [showMenu, setShowMenu] = React.useState(true)

	return (
		<>
			<Header />
			<div id="layoutSidenav">
				{showMenu && <MenuBar setShowMenu={setShowMenu} showMenu={showMenu} />}
				{!showMenu && <Sidebar setShowMenu={setShowMenu} showMenu={showMenu} />}
				<div id="layoutSidenav_content">
					<main style={{ paddingTop: "1rem", background: "whitesmoke" }}>
						<div className="container-fluid">
							<Switch>
								<Route path="/" exact>
									<QuoteDashboard />
								</Route>
								<Route path="/archived" exact>
									<ArchivedQuotes />
								</Route>
								<Route path="/draft" exact>
									<DraftQuotes />
								</Route>
								<Route path="/quotes" exact>
									<QuoteDashboard />
								</Route>
								<Route path="/quote/add" exact>
									<AddQuote />
								</Route>
								<Route path="/quote/edit" exact>
									<AddQuote />
								</Route>
								<Route path="/products" exact>
									<ProductsDashboard />
								</Route>
								<Route path="/products/add" exact>
									<AddProduct />
								</Route>
								<Route path="/products/edit" exact>
									<EditProduct />
								</Route>
								<Route path="/accessories" exact>
									<AccessoriesDashboard />
								</Route>
								<Route path="/accessories/add" exact>
									<AddAccessory />
								</Route>
								<Route path="/accessories/edit" exact>
									<EditAccessory />
								</Route>
								<Route path="/customers" exact>
									<CustomersDashboard />
								</Route>
								<Route path="/customers/add" exact>
									<AddCustomer />
								</Route>
								<Route path="/customers/edit" exact>
									<EditCustomer />
								</Route>
								<Route path="/customers/addresses/add" exact>
									<AddAddressContact />
								</Route>
								<Route path="/customers/addresses/edit" exact>
									<EditAddress />
								</Route>
								<Route path="/customers/contacts/add" exact>
									<AddContact />
								</Route>
								<Route path="/customers/contacts/edit" exact>
									<EditContact />
								</Route>
								<Route path="/users" exact>
									<UsersDashboard />
								</Route>
								<Route path="/users/add" exact>
									<AddUser />
								</Route>
								<Route path="/users/edit" exact>
									<EditUser />
								</Route>
								<Route path="/email-configuration" exact>
									<MailSettings />
								</Route>
								<Route path="/notes" exact>
									<Notes />
								</Route>
								<Route path="/notes/add" exact>
									<AddNotes />
								</Route>
							</Switch>
						</div>
					</main>
					<footer className="py-4 bg-light mt-auto">
						<div className="container-fluid">
							<div className="d-flex align-items-center justify-content-between small">
								<div className="text-muted">Copyright &copy; NeatHeat 2020</div>
								<div>
									<a href="#">Privacy Policy</a>
									&middot;
									<a href="#">Terms &amp; Conditions</a>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</>
	);
};

export default DashboardRoutes;
