import React, { useEffect, useState } from "react";
import { Modal, Table, Form } from "react-bootstrap";
import SelectProductModal from "./SelectProductModal";
import { useSelector } from "react-redux";
import { getQuotes, setEquipmentId } from "../../../data/reducers/quote.reducer";
import { useDispatch } from "react-redux";
import { deleteEquipment, getAllProducts, updateQuoteEquipments } from "../../../data/reducers/productx.reducer";
import './Modal.customization.scss'
const DeleteProductModal = ({
	modalTitle,
	inputFieldLabel,
	placeholder,
	isVisible,
	onSubmit,
	onCancel,
	rowId,
	quoteList
}) => {
	const [value, setValue] = useState("");
	const [show, setShow] = useState(false);
	const [deleteProductModal, setDeleteProductModal] = useState(false);
	const dispatch = useDispatch();
	const {quotes} = useSelector((state)=> state.quoteReducer)
	const [selectedEquipmentList, setSelectedEquipmentList] = useState([])
	const [equipId, setEquipId] = useState([])
	const [quoteId, setQuoteId] = useState(null)
	const products = useSelector((state) => state.productsXReducer).products;


	const fetchQuotes = async () =>{
		if(quotes === undefined){
			await dispatch(getQuotes())
		}
	}
	let filteredQuoteList = []
	let selectedQuoteList = []
	if(quotes){
		filteredQuoteList = quotes?.filter(quote=> quote?.lstQuoteEquipments?.find(equip => equip.equipmentId === rowId))
		selectedQuoteList = filteredQuoteList?.map(quote => ({selectedQuote:quote?.quoteDetails?.id, selectedEquip: quote?.lstQuoteEquipments?.find(equip => equip?.equipmentId === rowId)?.id}))
	} 

	const onClickOptionClickListener = (id, quoteSelectedId) => {
		let equipmentId= equipId.filter(equip=> equip?.selectedQuote !== quoteSelectedId)
		setEquipId([...equipmentId,{selectedEquip: id, selectedQuote: quoteSelectedId}])	
	}

	// useEffect(()=>{
	// 	setEquipId([...selectedQuoteList])
	// })

	useEffect(()=>{
		fetchQuotes()
	},[])

	useEffect(() => {
		setShow(isVisible);
	}, [isVisible]);



	const selectOnClickListener = (id) =>{
		setQuoteId(id)
		setDeleteProductModal(true)
	}


	const replaceAllClickListener = async () => {

		let list = equipId.map(equip => ({quoteId: parseInt(equip.selectedQuote), quoteEquipmentId: parseInt( equip.selectedEquip), equipmentId: parseInt(equip.selectedNewEquipment) }))
		console.log('this si list', list)
		await dispatch(updateQuoteEquipments(list))
		await dispatch(getAllProducts())
	}

	return (
		<>
			<Modal
				show={show}
				size="lg"
				onHide={() => {
					onCancel();
				}}
				style = {deleteProductModal ? {height:'50vh'} : '' }
			>
				<Modal.Header closeButton>{modalTitle}</Modal.Header>
				<Modal.Body>
					<p style={{ color: "red", textAlign: "center" }}>
						You have matching quotes associated with this equipment!
					</p>
					<form
						className="form"
						onSubmit={(e) => {
							e.preventDefault();
							onSubmit(value);
						}}
					>
						<Table striped bordered hover size="lg">
							<thead>
								<tr>
									<th>#</th>
									<th>Job Name</th>
									<th>Current Equipment</th>
									<th>New Equipment</th>
								</tr>
							</thead>
							<tbody>
								{filteredQuoteList?.map((quote,index )=>(<tr>
									<td>{index+1}</td>
									<td style={{ color: "#0A82F1" }}>
										{quote?.quoteDetails.jobName}
									</td>
									<td>
										<Form.Group>
											<Form.Control as="select" required onChange={(e)=> onClickOptionClickListener(e.target.value, quote?.quoteDetails?.id)}>
												<option key="0" value="">
													--- Select ---
												</option>

												{/* {quote?.lstQuoteEquipments?.map(equip => (
													<option 
													key={products?.find(product => product.id === equip.equipmentId).id} 
													value={equip?.id}>
														{products?.find(product => product.id === equip.equipmentId).equipmentCategoryName}
														</option>
												)
												)} */}
												<option value={quote?.lstQuoteEquipments?.find(equip => equip?.equipmentId === rowId)?.id} selected='selected'>
													{products?.find(product => product?.id === rowId)?.equipmentCategoryName}
												
												</option>
											</Form.Control>
										</Form.Group>
									</td>
									<td
										onClick={(e) => {
											selectOnClickListener(quote?.quoteDetails?.id)
										}}
										style={{ color: "#0A82F1", cursor: "pointer" }}
									>
										{equipId?.find(equip => equip?.selectedQuote?.toLocaleString() === quote?.quoteDetails?.id?.toLocaleString())?.selectedNewEquipment ?
										 products?.find(product => product?.id?.toLocaleString() === equipId?.find(equip => equip.selectedQuote?.toLocaleString() === quote?.quoteDetails?.id?.toLocaleString())?.selectedNewEquipment.toLocaleString())?.equipmentCategoryName
										 :
										  'Select'
										}
									</td>
								</tr>
								))}
							</tbody>
						</Table>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<button className="btn btn-primary mr-4" onClick={
								replaceAllClickListener
							}>Replace All</button>
							<button className="btn btn-danger" onClick={()=>{
								dispatch(deleteEquipment(rowId))
								dispatch(getAllProducts())
							}
							}>Delete</button>
						</div>
					</form>
				</Modal.Body>
				<SelectProductModal
				selectedQuoteList={selectedQuoteList}
				quoteId={quoteId}
				equipId={equipId}
				setEquipId={setEquipId}
				sleteEq
				selectedEquipmentList={selectedEquipmentList}
				setSelectedEquipmentList = {setSelectedEquipmentList}
				modalTitle="SELECT EQUIPMENT"
				isVisible={deleteProductModal}
				onSubmit={(value) => {
					setDeleteProductModal(false);
				}}
				onCancel={() => {
					setDeleteProductModal(false);
				}}
			/>
			</Modal>
		</>
	);
};

export default DeleteProductModal;
