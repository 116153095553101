import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllDropdownsAPI, getAllEmailsAPI, updateEmailsAPI } from "../services/configuration-data.service";

export const getDropdowns = createAsyncThunk(
    'configurationX/getDropdowns',
    async (_, thunkAPI) => {
        const response = await getAllDropdownsAPI();
        if (response.isSuccessful) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    },
);

export const getAllEmails = createAsyncThunk(
    "configurationX/getAllEmails",
    async (_, thunkAPI) => {
        const response = await getAllEmailsAPI();
        if (response.isSuccessful) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    },
);

export const updateEmails = createAsyncThunk(
    "configurationX/updateEmails",
    async (updatedEmails, thunkAPI) => {
        const response = await updateEmailsAPI(updatedEmails);
        if (response.isSuccessful) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    },
);

const configurationXSlice = createSlice({
    name: 'configurationX',
    initialState: {
        dropdowns: undefined,
        emails: {
            to: [],
            cc: [],
        },
    },
    reducers: {
        addToEmail: (state, action) => {
            state.emails.to.push(action.payload);
            state.emails.isDraft = true;
        },
        removeToEmail: (state, action) => {
            state.emails.to = state.emails.to.filter(e => e !== action.payload);
            state.emails.isDraft = true;
        },
        addCCEmail: (state, action) => {
            state.emails.cc.push(action.payload);
            state.emails.isDraft = true;
        },
        removeCCEmail: (state, action) => {
            state.emails.cc = state.emails.cc.filter(e => e !== action.payload);
            state.emails.isDraft = true;
        },
    },
    extraReducers: {
        [getDropdowns.fulfilled]: (state, action) => {
            state.dropdowns = action.payload
        },
        [getAllEmails.fulfilled]: (state, action) => {
            state.emails.to = action.payload.to.split(';').filter(e => e !== '');
            state.emails.cc = action.payload.cc.split(';').filter(e => e !== '');
            state.isDraft = false;
        },
        [updateEmails.fulfilled]: (state, action) => {
            state.emails.to = action.payload.to.split(';').filter(e => e !== '');
            state.emails.cc = action.payload.cc.split(';').filter(e => e !== '');
            state.isDraft = false;
        },
    },
});

export const configurationActions = configurationXSlice.actions;
export default configurationXSlice.reducer;