import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	refreshSummary,
	getQuotes,
	resetQuote,
	saveQuote,
	generateEmail,
	setDraftQuote,
	createVersion,
	setStatus,
	updateQuote,
	setJobName,
	setCustomer,
	updateArchiveQuote,
	getArchivedQuotes,
	getDraftQuotes,
	selectAllEquipmentAndAccessories,
	generateQuotePdf,
	setShippingCost,
	setBillingContactAndAddressId,
	setShippingContactAndAddressId,
} from "../../data/reducers/quote.reducer";
import { getCustomers } from "../../data/reducers/customerx.reducer";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import CustomerSelectionCard from "./CustomerSelectionCard";
import TermsCard from "./TermsCard";
import EquipmentList from "./EquipmentList";
import QuotePriceCard from "./QuotePriceCard";
import TicketsUtilityCard from "./TicketsUtilityCard";
import queryString from "query-string";
import DocumentCard from "./DocumentCard";
import "./single-quote.scss";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";
import { withLoader } from "../../utils/hoc/withLoader";

const AddQuote = ({ setBusy }) => {
	const dispatch = useDispatch();
	const inputRef = useRef();
	const history = useHistory();
	const location = useLocation();
	const { customers } = useSelector((state) => state.customerXReducer);
	const selectedCustomer = useSelector(
		(state) => state.quoteReducer.draftQuote?.quoteDetails.selectedCustomer
	);

	const quoteId = queryString.parse(location.search).id;
	const customerId = queryString.parse(location.search).customerId;
	// console.log("customerId", customerId);
	const fetchAllCustomers = async () => {
		await dispatch(getCustomers());
	};
	// useEffect(() => {
	// 	inputRef.current.focus();
	// });
	useEffect(() => {
		fetchAllCustomers();
	}, []);
	useEffect(() => {
		if (customers && customerId && selectedCustomer === undefined) {
			let customer = customers.find(
				(customer) => customer?.customerDetails?.id === customerId
			);
			if (customer) {
				dispatch(setCustomer(customer));
			}
		}
	}, [customerId, customers, dispatch, selectedCustomer]);
	// useEffect(() => {
	// 	if (customerId && customers) {
	// 		console.log("it is exce ");

	// 		let customer = customers.find(
	// 			(customer) => customer?.customerDetails?.id === customerId
	// 		);
	// 		if (customer) {
	// 			dispatch(setCustomer(customer));
	// 		}
	// 		dispatch(setBillingContactAndAddressId(0));
	// 		dispatch(setShippingContactAndAddressId(0));
	// 	}
	// }, [customers, customerId]);
	const { draftQuote, quotes, archivedQuotes, draftQuotes } = useSelector((state) => state.quoteReducer);
	const { dropdowns } = useSelector((state) => state.configurationXReducer);
	const { products } = useSelector((state) => state.productsXReducer);
	const { accessories } = useSelector((state) => state.accessoryXReducer);
	const salesTax = useSelector((state) => state.salesTaxReducer)?.salesTaxes;
	// console.log(draftQuotes);
	const archived = () => {
		dispatch(updateArchiveQuote([draftQuote?.quoteDetails?.id]));
		dispatch(getArchivedQuotes());
		dispatch(getQuotes());
	};
	useEffect(() => {
		dispatch(refreshSummary());
	}, [draftQuote, dispatch]);

	useEffect(() => {
		if (quotes === undefined) {
			dispatch(getQuotes());
		}
		if (archivedQuotes === undefined) {
			dispatch(getArchivedQuotes());
		}
		if (draftQuotes === undefined) {
			dispatch(getDraftQuotes());
		}

	}, []);

	const setQuote = async (quote) => {
		let tempQuote = {
			...quote,
			lstQuoteEquipments: quote.lstQuoteEquipments.map((q) => ({
				...q,
				selected: true,
			})),
			lstQuoteAccessories: quote.lstQuoteAccessories.map((q) => ({
				...q,
				selected: true,
			})),
		};
		await dispatch(setDraftQuote(tempQuote));
	};
	useEffect(() => {
		if (quoteId === undefined) {
			if (!draftQuote) {
				dispatch(resetQuote());
				dispatch(setShippingCost(800));
			}
		} else {
			if (
				quotes !== undefined &&
				archivedQuotes !== undefined &&
				draftQuotes !== undefined &&
				quoteId !== undefined &&
				parseInt(quoteId) > 0
				// && (draftQuote === undefined || draftQuote.quoteDetails === undefined || draftQuote.quoteDetails.id === undefined)
			) {
				let quote = quotes.find(
					(quote) => quote.quoteDetails.id === parseInt(quoteId)
				);
				let archivedquote = archivedQuotes.find(
					(quote) => quote.quoteDetails.id === parseInt(quoteId)
				);
				let draftquotes = draftQuotes.find(
					(quote) => quote.quoteDetails.id === parseInt(quoteId)
				);
				if (quote) {
					setQuote(quote);
				} else if (archivedquote) {
					setQuote(archivedquote)
				} else {
					setQuote(draftquotes)
				}
			}
		}
	}, [quoteId, quotes, archivedQuotes, draftQuotes]);

	const createQuote = async () => {
		try {
			let quote = { ...draftQuote };
			quote.lstQuoteEquipments = quote.lstQuoteEquipments.map(
				({ id, ...equipment }) => equipment
			);
			quote.lstQuoteAccessories = quote.lstQuoteAccessories.map(
				({ id, ...accessory }) => accessory
			);
			var response = await dispatch(saveQuote(quote));
			await dispatch(successMessage("Quote created successfully!"));
			history.push("/quote/edit?id=" + response.payload.quoteDetails.id);
			await dispatch(getQuotes());
		} catch (e) {
			console.log(e);
		}
	};

	const update = async () => {
		try {
			let quote = { ...draftQuote };
			setBusy(true);
			quote.lstQuoteEquipments = quote.lstQuoteEquipments.map(
				({ id, ...equipment }) => equipment
			);
			quote.lstQuoteAccessories = quote.lstQuoteAccessories.map(
				({ id, ...accessory }) => accessory
			);
			await dispatch(updateQuote(quote));
			await dispatch(successMessage("Quote updated successfully!"));
			// history.push('/');
			await dispatch(getQuotes());
		} catch (e) {
			console.log(e);
		} finally {
			setBusy(false);
		}
	};
	const generateMail = async (isDetailed) => {
		let data = {
			date: new Date(draftQuote.quoteDetails.createdOn).toLocaleDateString(),
			jobName: draftQuote.quoteDetails.jobName,
			billingName: draftQuote.quoteDetails.customerName,
			//billingName: ((draftQuote.quoteDetails.billingContact?.firstName != null && draftQuote.quoteDetails.billingContact?.firstName != undefined )? draftQuote.quoteDetails.billingContact?.firstName : "") + ' ' + ((draftQuote.quoteDetails.billingContact?.lastName != null && draftQuote.quoteDetails.billingContact?.lastName != undefined )? draftQuote.quoteDetails.billingContact?.lastName : ""),
			billingCompanyName:
				(draftQuote.quoteDetails.billingContactAndAddress?.firstName != null &&
					draftQuote.quoteDetails.billingContactAndAddress?.firstName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.billingContactAndAddress?.lastName != null &&
					draftQuote.quoteDetails.billingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.lastName
					: ""),
			// +
			// " " +
			// (draftQuote.quoteDetails.billingContact?.lastName != null &&
			// 	draftQuote.quoteDetails.billingContact?.lastName != undefined
			// 	? draftQuote.quoteDetails.billingContact?.lastName
			// 	: ""),
			billingAddress1:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
			billingAddress2:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
			city1: draftQuote.quoteDetails.billingContactAndAddress?.city,
			state1: draftQuote.quoteDetails.billingContactAndAddress?.state,
			zipCode1: draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
			billingPhone: draftQuote.quoteDetails.billingContactAndAddress?.phone,
			billingEmail: draftQuote.quoteDetails.billingContactAndAddress?.email,
			shippingName: draftQuote.quoteDetails.customerName,
			shippingCompanyName:
				(draftQuote.quoteDetails.shippingContactAndAddress?.firstName != null &&
					draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContactAndAddress?.lastName != null &&
					draftQuote.quoteDetails.shippingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
					: ""),
			// +
			// " " +
			// (draftQuote.quoteDetails.billingContact?.lastName != null &&
			// 	draftQuote.quoteDetails.billingContact?.lastName != undefined
			// 	? draftQuote.quoteDetails.billingContact?.lastName
			// 	: ""),
			shippingAddress1:
				draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
			shippingAddress2:
				draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
			city2: draftQuote.quoteDetails.shippingContactAndAddress?.city,
			state2: draftQuote.quoteDetails.shippingContactAndAddress?.state,
			zipCode2: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
			notes: draftQuote.quoteDetails.specialNotes,
			shippingPhone: draftQuote.quoteDetails.shippingContactAndAddress?.phone,
			shippingEmail: draftQuote.quoteDetails.shippingContactAndAddress?.email,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			termsOfPayment: draftQuote.quoteDetails.termsOfPayment?.termsPayment,
			isDetailed: isDetailed,
			equipmentList: [
				...draftQuote.lstQuoteEquipments?.map((equipment) => ({
					equipmentCategory: products.find(
						(product) => product.id === equipment.equipmentId
					).equipmentCategoryName,
					equipmentId: equipment.equipmentId,
					model: products.find(
						(product) => product.id === equipment.equipmentId
					).modelOutputname,
					electrical: null,
					sizing: null,
					quantity: equipment.quantity + "",
					price: "$ " + formatMoney(equipment.price?.toFixed(2)),
					total: "$ " + formatMoney(equipment.total?.toFixed(2)),
				})),
				...draftQuote.lstQuoteAccessories?.map((accessory) => ({
					model: accessories.find((acc) => acc.id === accessory.accessoryId)
						? accessories.find((acc) => acc.id === accessory.accessoryId).name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				})),
			],
			billingDetailsValue: {
				shippingQuantity: draftQuote.quoteDetails?.shippingNumber,
				shippingPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.shippingCost?.toFixed(2)),
				shippingTotal:
					"$ " +
					formatMoney(draftQuote.quoteDetails?.shippingTotal?.toFixed(2)),
				otherQuantity: draftQuote.quoteDetails.otherNumber,
				otherPrice:
					"$ " + formatMoney(draftQuote.quoteDetails.otherCost.toFixed(2)),
				otherTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.otherTotal.toFixed(2)),
				subTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.subTotal.toFixed(2)),
				salesTaxPercentage: salesTax.find(
					(tax) => tax.id === draftQuote.quoteDetails.salesTaxId
				).salesTaxName,
				rate: draftQuote.quoteDetails.salesTaxVal.toString(),
				rateMultiplier: "1",
				rateMultiplicand: "",
				salesTaxTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
				ccFeeMultiplier: draftQuote.quoteDetails.ccFeeEnabled ? 1 : 0,
				ccFeeMultiplicand: null,
				ccFeeTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
				billingTotalValue:
					"$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
			},
		};
		try {
			setBusy(true);
			await dispatch(generateEmail(data));
		} finally {
			setBusy(false);
		}
	};

	const createQuoteVersion = async () => {
		try {
			let response = await dispatch(
				createVersion({
					...draftQuote,
					quoteDetails: {
						...draftQuote.quoteDetails,
						id: 0,
						masterQuoteId: quoteId,
					},
				})
			);
			debugger;
			if (response.error) {
				await dispatch(errorMessage(response.error.message));
			} else {
				history.push("/quote/edit?id=" + response.payload.quoteDetails.id);
				await dispatch(successMessage("copy created successfully!"));
			}
		} catch (ex) {
			await dispatch(errorMessage(ex));
		}
	};

	const quoteButtonClickListener = async (value) => {
		let data = {
			date: new Date().toLocaleString(),
			jobName: draftQuote?.quoteDetails?.jobName,
			billingName: draftQuote.quoteDetails.customerName,
			billingAddress1:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
			billingAddress2:
				draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
			city1: draftQuote.quoteDetails.billingContactAndAddress?.city,
			state1: draftQuote?.quoteDetails?.billingContactAndAddress?.state,
			zipCode1: draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
			billingPhone: draftQuote.quoteDetails.billingContactAndAddress?.phone,
			billingEmail: draftQuote.quoteDetails.billingContactAndAddress?.email,
			billingCompanyName:
				(draftQuote.quoteDetails.billingContactAndAddress?.firstName != null &&
					draftQuote.quoteDetails.billingContactAndAddress?.firstName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.billingContactAndAddress?.lastName != null &&
					draftQuote.quoteDetails.billingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.billingContactAndAddress?.lastName
					: ""),
			shippingName: draftQuote.quoteDetails.customerName,
			shippingCompanyName:
				(draftQuote.quoteDetails.shippingContactAndAddress?.firstName != null &&
					draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
					undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContactAndAddress?.lastName != null &&
					draftQuote.quoteDetails.shippingContactAndAddress?.lastName != undefined
					? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
					: ""),
			shippingAddress1:
				draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1,
			shippingAddress2:
				draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2,
			city2: draftQuote?.quoteDetails?.shippingContactAndAddress?.city,
			state2: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
			zipCode2: draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode,
			notes: draftQuote?.quoteDetails?.specialNotes,
			shippingPhone: draftQuote?.quoteDetails?.shippingContactAndAddress?.phone,
			shippingEmail: draftQuote?.quoteDetails?.shippingContactAndAddress?.email,
			rentalTimeFrame:
				draftQuote?.quoteDetails?.rentalTimeFrame?.rentalTimeFrame,
			termsOfPayment: draftQuote?.quoteDetails?.termsOfPayment?.termsPayment,
			isDetailed: value,
			notes: draftQuote?.quoteDetails?.specialNotes,
			equipmentList: [
				...draftQuote.lstQuoteEquipments?.map((equipment) => ({
					equipmentCategory: products.find(
						(product) => product.id === equipment.equipmentId
					).equipmentCategoryName,
					equipmentId: equipment.equipmentId,
					model: products.find(
						(product) => product.id === equipment.equipmentId
					).modelOutputname,
					electrical: null,
					sizing: null,
					quantity: equipment.quantity + "",
					price: "$ " + formatMoney(equipment.price?.toFixed(2)),
					total: "$ " + formatMoney(equipment.total?.toFixed(2)),
				})),
				...draftQuote.lstQuoteAccessories?.map((accessory) => ({
					model: accessories.find((acc) => acc.id === accessory.accessoryId)
						? accessories.find((acc) => acc.id === accessory.accessoryId).name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				})),
			],
			billingDetailsValue: {
				shippingQuantity: draftQuote.quoteDetails?.shippingNumber,
				shippingPrice:
					"$ " + formatMoney(draftQuote.quoteDetails?.shippingCost?.toFixed(2)),
				shippingTotal:
					"$ " +
					formatMoney(draftQuote.quoteDetails?.shippingTotal?.toFixed(2)),
				otherQuantity: draftQuote.quoteDetails.otherNumber,
				otherPrice:
					"$ " + formatMoney(draftQuote.quoteDetails.otherCost.toFixed(2)),
				otherTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.otherTotal.toFixed(2)),
				subTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.subTotal.toFixed(2)),
				salesTaxPercentage: salesTax.find(
					(tax) => tax.id === draftQuote.quoteDetails.salesTaxId
				).salesTaxName,
				rate: draftQuote.quoteDetails.salesTaxVal.toString(),
				rateMultiplier: "1",
				rateMultiplicand: "",
				salesTaxTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
				ccFeeMultiplier: draftQuote.quoteDetails.ccFeeEnabled ? 1 : 0,
				ccFeeMultiplicand: null,
				ccFeeTotal:
					"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
				billingTotalValue:
					"$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
			},
		};
		try {
			setBusy(true);
			await dispatch(generateQuotePdf(data));
		} finally {
			setBusy(false);
		}
	};
	// console.log(draftQuote);
	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/quotes">Quotes</Link>
				</li>
				<li className="breadcrumb-item active">
					{quoteId > 0 ? "Edit" : "Add New"} Quote
				</li>
			</ol>
			<div className="row single-quote">
				<div className="col-md-12 col-lg-12">
					{/* <div className='d-flex col-md-12 col=lg-12'> */}
					{/* <div className='d-flex flex-column d-sm-block col-md-8 col-6'>
                <h2>{quoteId > 0 ? 'Edit' : 'Add New'} Quote</h2>
                <p>Fill up Quote Details</p>
              </div> */}
					{/* {quoteId && <div className='d-flex align-self-center col-md-4 col-lg-4 col-6'>
              <div className='mr-1'>
                <button className='btn btn-success btn-sm btn-block'>Archive</button>
              </div>
              <div className='mr-1'>
                <button className='btn btn-primary btn-sm btn-block'>Upload Document</button>
              </div>
              <div className=''>
                <button className='btn btn-primary btn-sm btn-block '>Generate Tickets</button>
              </div>
            </div>} */}
					{/* </div> */}
					<div className="row">
						<div className="col-md-6 col-lg-8">
							<div>
								<h2>{quoteId > 0 ? "Edit" : "Add New"} Quote</h2>
								<p>Fill up Quote Details</p>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 p-2 ml-2 align-items-center row">
							{draftQuote && quoteId ? (
								<div className="mr-1">
									<button
										className="btn btn-success btn-sm btn-block m-1"
										onClick={(e) => {
											e.preventDefault();
											archived();
										}}
									>
										Archive
									</button>
								</div>
							) : (
								""
							)}
							<div className="mr-1">
								<button className="btn btn-primary btn-sm btn-block m-1">
									<ScrollLink
										to="UploadDocumentScroll"
										spy={true}
										smooth={true}
									>
										Upload Document
									</ScrollLink>
								</button>
							</div>
							<div className="">
								<button className="btn btn-primary btn-sm btn-block m-1">
									<ScrollLink
										to="TicketGenerationCard"
										spy={true}
										smooth={true}
									>
										Generate Tickets
									</ScrollLink>
								</button>
							</div>
						</div>
					</div>
					{draftQuote ? (
						<div className="card">
							<div className="card-body container-fluid">
								<form className="row form">
									<div className="col-md-4 form-group d-flex flex-column">
										<label>
											<b>Job Name</b>
										</label>
										<input
											// ref={inputRef}
											// ref={input => input && input.focus()}
											value={draftQuote?.quoteDetails?.jobName}
											onChange={(e) => {
												e.preventDefault();
												dispatch(setJobName(e.target.value));
											}}
											type="text"
											className="form-control form-control-sm"
										/>
									</div>
									{quoteId ? (
										<div className="col-md-2 form-group d-flex flex-column">
											<label>&nbsp;</label>
											<button
												className="btn btn-primary btn-sm form-control form-control-sm"
												onClick={(e) => {
													e.preventDefault();
													update();
												}}
											>
												Update
											</button>
										</div>
									) : (
										""
									)}
								</form>
							</div>
						</div>
					) : (
						""
					)}
					{draftQuote && quoteId ? (
						<div className="card mt-1">
							<div className="card-body container-fluid">
								<form className="row form">
									<div className="col-md-4 form-group d-flex flex-column">
										<div className="d-flex justify-content-between">
											<label>
												<b>Job No.</b>
											</label>
											<div className="text-right">
												<button
													className="btn btn-sm btn-link"
													onClick={(e) => {
														e.preventDefault();
														createQuoteVersion();
													}}
												>
													create a copy
												</button>
											</div>
										</div>
										<input
											value={quoteId}
											disabled
											className="form-control form-control-sm"
										></input>
									</div>
									<div className="col-md-4 form-group d-flex flex-column">
										<label>
											<b>Quote Status</b>
										</label>
										<select
											className="form-control form-control-sm"
											value={draftQuote.quoteDetails.status}
											onChange={(e) => {
												let status = dropdowns?.quoteStatus?.find(
													(st) => st.id === parseInt(e.target.value)
												);
												dispatch(setStatus(status.id));
											}}
										>
											<option value="0">---Select---</option>
											{dropdowns?.quoteStatus?.map((status) => (
												<option key={status.id} value={status.id}>
													{status.status}
												</option>
											))}
										</select>
									</div>
									<div className="col-md-2 form-group d-flex flex-column">
										<label>&nbsp;</label>
										<button
											className="btn btn-primary btn-sm form-control form-control-sm"
											onClick={(e) => {
												e.preventDefault();
												update();
											}}
										>
											Update
										</button>
									</div>
								</form>
							</div>
						</div>
					) : (
						""
					)}
					{draftQuote ? <CustomerSelectionCard /> : ""}
					{draftQuote ? <TermsCard /> : ""}
					{draftQuote ? <EquipmentList /> : ""}
					{/* {draftQuote ? <QuotePriceCard /> : ""} */}
					<div className="row mt-2">
						<div className="col-12 col-md-6">
							<button
								className="btn btn-primary btn-sm"
								onClick={(e) => {
									quoteId > 0 ? update() : createQuote();
								}}
							>
								{quoteId > 0 ? "Update" : "Save"}
							</button>
							&nbsp; &nbsp;
							<button className="btn btn-danger btn-sm">Go Back</button>
						</div>

						{draftQuote && quoteId ? (
							<>
								<div
									className="col-12 d-flex col-md-6"
									style={{ justifyContent: "flex-end" }}
								>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(false)}
										>
											Quote(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(true)}
										>
											Quote(Detailed)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(false);
											}}
										>
											Email(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(true);
											}}
										>
											Email(Detailed)
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								<div
									className="col-12 d-flex col-md-6"
									style={{ justifyContent: "flex-end" }}
								>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(false)}
										>
											Quote(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={() => quoteButtonClickListener(true)}
										>
											Quote(Detailed)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(false);
											}}
										>
											Email(Summarized)
										</button>
									</div>
									<div className="mr-1 mt-1">
										<button
											className="btn btn-secondary btn-sm btn-block"
											onClick={(e) => {
												e.preventDefault();
												generateMail(true);
											}}
										>
											Email(Detailed)
										</button>
									</div>
								</div>
							</>
						)}
					</div>
					<br />
					{draftQuote && <TicketsUtilityCard setBusy={setBusy} />}
					{draftQuote && quoteId ? <DocumentCard quoteId={quoteId} /> : ""}
				</div>
			</div>
		</>
	);
};

export default withLoader(AddQuote);

const formatMoney = (number, decPlaces, decSep, thouSep) => {
	if (number === undefined) {
		number = 0;
	}
	var decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
		decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	var sign = number < 0 ? "-" : "";
	var i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
	);
	var j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces
			? decSep +
			Math.abs(number - i)
				.toFixed(decPlaces)
				.slice(2)
			: "")
	);
};
