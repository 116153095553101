import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";

import { connect, useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../data/reducers/authx.reducer";
import {
	infoMessage,
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";

import neatheatLogo from "../../assets/images/neatheat-logo.png";
import loginLogo from "../../assets/images/loginLogo.png";
// import neatheatLogo1 from "../../assets/images/neatheatLogo1.png";
import techdomlogo from "../../assets/images/techdomlogo.png";
import "./Login.scss";

const Login = () => {
	const dispatch = useDispatch();
	const authInfo = useSelector((state) => state.authXReducer);

	// state
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const [isLoading, setLoading] = useState(false);

	const login = async () => {
		try {
			setLoading(true);
			const res = await dispatch(loginUser({ username, password }));
			if (res.error) {
				dispatch(errorMessage(res.error.message));
			} else {
				dispatch(successMessage("Login successful!"));
			}
		} catch (e) {
			console.log(e);
			dispatch(errorMessage(e.message));
		} finally {
			setLoading(false);
		}
	};

	return (
		<div fluid className="login-page">
			{isLoading && (
				<div className="loading-overlay">
					<div className="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
					<div className="text-white h3">Signing You In..</div>
				</div>
			)}
			<Row>
				<Col className="login-page-left-bg md-col-6">
					<div className="center login-page-left-col">
						<img
							src={techdomlogo}
							alt="background"
							height="70"
							weight="70"
						></img>
					</div>
				</Col>
				<Col
					className="login-bgimage md-col-6 "
					style={{ alignSelf: "center" }}
				>
					<div className="gradient">
						<div className="loginform">
							<img
								src={loginLogo}
								alt="Login"
								style={{ padding: "20px 135px" }}
							/>
							<h2>Sign In</h2>
							<Form onSubmit={() => login()}>
								<Form.Group>
									<Form.Label>User Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter username"
										name="username"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										required
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										name="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								</Form.Group>
								<Button
									type="submit"
									className="login-btn"
									variant="primary"
									style={{ marginTop: "1.5rem" }}
									onClick={(e) => {
										e.preventDefault();
										login();
									}}
								>
									Login
								</Button>

								<Row>
									{/* <Col>
                                        <Form.Group style={{ marginTop: '0.8rem' }}>
                                            <div><a href="#" className="forgot-pwd">Forgot Password?</a></div>
                                        </Form.Group>
                                    </Col> */}
									{/* <Col className='text-right'>
                                        <Form.Group style={{ marginTop: '0.8rem' }}>
                                            <div><a href="#" onClick={() => { setUserType(userType == 'Doctor' ? 'Patient' : 'Doctor') }} className="forgot-pwd">Looking for {(userType == 'Patient') ? (<span>Doctor</span>) : (<span>Patient</span>)} Login?</a></div>
                                        </Form.Group>
                                    </Col> */}
								</Row>
							</Form>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default Login;
