import React, { useState } from "react";
import { Button, ButtonGroup, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useDispatch, useSelector } from "react-redux";
import {
	generateReservationTicket,
	generateDeliveryTicket,
	generatePickupTicket,
	generateInvoiceTemplate,
	generateServiceTicket,
	generateSwapoutTicket,
	printReservationTicket,
	printDeliveryTicket,
	printPickupTicket,
	printSwapoutTicket,
	printServiceTicket,
	printInvoiceTicket,
} from "../../data/reducers/quote.reducer";
import { Collapse } from "react-bootstrap";

import Reservation from "./Popups/Reservation.popup";
import Delivery from "./Popups/Delivery";
import Pickup from "./Popups/Pickup";
import Swapout from "./Popups/Swapout";
import Service from "./Popups/Service";
import Invoice from "./Popups/Invoice";
const TicketsUtilityCard = ({ setBusy }) => {
	const dispatch = useDispatch();

	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const products = useSelector((state) => state.productsXReducer)?.products;
	const accessories = useSelector(
		(state) => state.accessoryXReducer
	)?.accessories;
	const [ReservationCardShow, setReservationCardShow] = React.useState(false);
	const [DeliveryCard, setDeliveryCard] = React.useState(false);
	const [showInvoice, setShowInvoice] = React.useState(false);

	const [showPickup, setShowPickup] = React.useState(false);
	const [SwapoutCard, setSwapoutCard] = React.useState(false);
	const [ServiceCard, setService] = React.useState(false);
	const reservationTicket = async (type) => {
		let data = {
			dateEquipmentNeeded: new Date().toLocaleDateString(),
			billOfLanding: "",
			// customerName: draftQuote.quoteDetails.customerName,
			customerName:
				(draftQuote.quoteDetails.shippingContact?.firstName != null &&
					draftQuote.quoteDetails.shippingContact?.firstName != undefined
					? draftQuote.quoteDetails.shippingContact?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContact?.lastName != null &&
					draftQuote.quoteDetails.shippingContact?.lastName != undefined
					? draftQuote.quoteDetails.shippingContact?.lastName
					: ""),
			companyName: draftQuote.quoteDetails.customerName,
			address1: draftQuote.quoteDetails.shippingAddress?.addressLine1,
			address2: draftQuote.quoteDetails.shippingAddress?.addressLine2,
			city: draftQuote.quoteDetails.billingAddress?.city,
			zipCode: draftQuote.quoteDetails.billingAddress?.zipCode,
			phone: draftQuote.quoteDetails.billingContact?.phone,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			equipments: [
				...draftQuote.lstQuoteEquipments
					?.filter((equipment) => equipment.selected === true)
					.map((equipment) => ({
						equipment: products.find((p) => p.id === equipment.equipmentId)
							.equipmentCategoryName,
						equipmentId: equipment.id,
						model: products.find((p) => p.id === equipment.equipmentId)
							.modelOutputname,
						static1: "",
						static2: "",
						quantity: equipment.quantity.toString(),
					})),
				...draftQuote.lstQuoteAccessories
					?.filter((accessory) => accessory.selected === true)
					.map((accessory) => ({
						model: accessories.find((acc) => acc.id === accessory.accessoryId)
							? accessories.find((acc) => acc.id === accessory.accessoryId).name
							: "accessory",
						static1: "",
						static2: "",
						quantity: accessory.quantity.toString(),
					})),
			],
		};
		let action =
			type === "download"
				? generateReservationTicket(data)
				: printReservationTicket(data);
		try {
			setBusy(true);
			await dispatch(action);
		} finally {
			setBusy(false);
		}
	};

	const deliveryTicket = async (type) => {
		let data = {
			dateEquipmentNeeded: new Date().toLocaleDateString(),
			billOfLanding: "",
			// customerName: draftQuote.quoteDetails.customerName,
			customerName:
				(draftQuote.quoteDetails.shippingContact?.firstName != null &&
					draftQuote.quoteDetails.shippingContact?.firstName != undefined
					? draftQuote.quoteDetails.shippingContact?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContact?.lastName != null &&
					draftQuote.quoteDetails.shippingContact?.lastName != undefined
					? draftQuote.quoteDetails.shippingContact?.lastName
					: ""),
			companyName: draftQuote.quoteDetails.customerName,
			address1: draftQuote.quoteDetails.shippingAddress?.addressLine1,
			address2: draftQuote.quoteDetails.shippingAddress?.addressLine2,
			city: draftQuote.quoteDetails.shippingAddress?.city,
			zipCode: draftQuote.quoteDetails.shippingAddress?.zipCode,
			phone: draftQuote.quoteDetails.shippingContact?.phone,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			equipments: [
				...draftQuote.lstQuoteEquipments
					?.filter((equipment) => equipment.selected === true)
					.map((equipment) => ({
						equipment: products.find((p) => p.id === equipment.equipmentId)
							.equipmentCategoryName,
						model: products.find((p) => p.id === equipment.equipmentId)
							.modelOutputname,
						static1: "",
						static2: "",
						quantity: equipment.quantity.toString(),
					})),
				...draftQuote.lstQuoteAccessories
					?.filter((accessory) => accessory.selected === true)
					.map((accessory) => ({
						model: accessories.find((acc) => acc.id === accessory.accessoryId)
							? accessories.find((acc) => acc.id === accessory.accessoryId).name
							: "accessory",
						static1: "",
						static2: "",
						quantity: accessory.quantity.toString(),
					})),
			],
		};
		let action =
			type === "download"
				? generateDeliveryTicket(data)
				: printDeliveryTicket(data);
		try {
			setBusy(true);
			await dispatch(action);
		} finally {
			setBusy(false);
		}
	};

	const pickupTicket = async (type) => {
		let data = {
			dateEquipmentNeeded: new Date().toLocaleDateString(),
			billOfLanding: "",
			// customerName: draftQuote.quoteDetails.customerName,
			customerName:
				(draftQuote.quoteDetails.shippingContact?.firstName != null &&
					draftQuote.quoteDetails.shippingContact?.firstName != undefined
					? draftQuote.quoteDetails.shippingContact?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContact?.lastName != null &&
					draftQuote.quoteDetails.shippingContact?.lastName != undefined
					? draftQuote.quoteDetails.shippingContact?.lastName
					: ""),
			companyName: draftQuote.quoteDetails.customerName,
			address1: draftQuote.quoteDetails.shippingAddress?.addressLine1,
			address2: draftQuote.quoteDetails.shippingAddress1?.addressLine2,
			city: draftQuote.quoteDetails.shippingAddress?.city,
			zipCode: draftQuote.quoteDetails.shippingAddress?.zipCode,
			phone: draftQuote.quoteDetails.shippingContact?.phone,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			equipments: [
				...draftQuote.lstQuoteEquipments
					?.filter((equipment) => equipment.selected === true)
					.map((equipment) => ({
						equipment: products.find((p) => p.id === equipment.equipmentId)
							.equipmentCategoryName,
						model: products.find((p) => p.id === equipment.equipmentId)
							.modelOutputname,
						static1: "",
						static2: "",
						quantity: equipment.quantity.toString(),
					})),
				...draftQuote.lstQuoteAccessories
					?.filter((accessory) => accessory.selected === true)
					.map((accessory) => ({
						model: accessories.find((acc) => acc.id === accessory.accessoryId)
							? accessories.find((acc) => acc.id === accessory.accessoryId).name
							: "accessory",
						static1: "",
						static2: "",
						quantity: accessory.quantity.toString(),
					})),
			],
		};
		let action =
			type === "download"
				? generatePickupTicket(data)
				: printPickupTicket(data);
		try {
			setBusy(true);
			await dispatch(action);
		} finally {
			setBusy(false);
		}
	};

	const swapoutTicket = async (type) => {
		let data = {
			dateEquipmentNeeded: new Date().toLocaleDateString(),
			billOfLanding: "",
			// customerName: draftQuote.quoteDetails.customerName,
			customerName:
				(draftQuote.quoteDetails.shippingContact?.firstName != null &&
					draftQuote.quoteDetails.shippingContact?.firstName != undefined
					? draftQuote.quoteDetails.shippingContact?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContact?.lastName != null &&
					draftQuote.quoteDetails.shippingContact?.lastName != undefined
					? draftQuote.quoteDetails.shippingContact?.lastName
					: ""),
			companyName: draftQuote.quoteDetails.customerName,
			address1: draftQuote.quoteDetails.shippingAddress?.addressLine1,
			address2: draftQuote.quoteDetails.shippingAddress?.addressLine2,
			city: draftQuote.quoteDetails.shippingAddress?.city,
			zipCode: draftQuote.quoteDetails.shippingAddress?.zipCode,
			phone: draftQuote.quoteDetails.shippingContact?.phone,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			equipments: [
				...draftQuote.lstQuoteEquipments
					?.filter((equipment) => equipment.selected === true)
					.map((equipment) => ({
						equipment: products.find((p) => p.id === equipment.equipmentId)
							.equipmentCategoryName,
						model: products.find((p) => p.id === equipment.equipmentId)
							.modelOutputname,
						static1: "",
						static2: "",
						quantity: equipment.quantity.toString(),
					})),
				...draftQuote.lstQuoteAccessories
					?.filter((accessory) => accessory.selected === true)
					.map((accessory) => ({
						model: accessories.find((acc) => acc.id === accessory.accessoryId)
							? accessories.find((acc) => acc.id === accessory.accessoryId).name
							: "accessory",
						static1: "",
						static2: "",
						quantity: accessory.quantity.toString(),
					})),
			],
		};
		let action =
			type === "download"
				? generateSwapoutTicket(data)
				: printSwapoutTicket(data);
		try {
			setBusy(true);
			await dispatch(action);
		} finally {
			setBusy(false);
		}
	};

	const serviceTicket = async (type) => {
		let data = {
			dateEquipmentNeeded: new Date().toLocaleDateString(),
			billOfLanding: "",
			// customerName: draftQuote.quoteDetails.customerName,
			customerName:
				(draftQuote.quoteDetails.shippingContact?.firstName != null &&
					draftQuote.quoteDetails.shippingContact?.firstName != undefined
					? draftQuote.quoteDetails.shippingContact?.firstName
					: "") +
				" " +
				(draftQuote.quoteDetails.shippingContact?.lastName != null &&
					draftQuote.quoteDetails.shippingContact?.lastName != undefined
					? draftQuote.quoteDetails.shippingContact?.lastName
					: ""),
			companyName: draftQuote.quoteDetails.customerName,
			address1: draftQuote.quoteDetails.shippingAddress?.addressLine1,
			address2: draftQuote.quoteDetails.shippingAddress?.addressLine2,
			city: draftQuote.quoteDetails.shippingAddress?.city,
			zipCode: draftQuote.quoteDetails.shippingAddress?.zipCode,
			phone: draftQuote.quoteDetails.shippingContact?.phone,
			rentalTimeFrame: draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
			equipments: [
				...draftQuote.lstQuoteEquipments
					?.filter((equipment) => equipment.selected === true)
					.map((equipment) => ({
						equipment: products.find((p) => p.id === equipment.equipmentId)
							.equipmentCategoryName,
						model: products.find((p) => p.id === equipment.equipmentId)
							.modelOutputname,
						static1: "",
						static2: "",
						quantity: equipment.quantity.toString(),
					})),
				...draftQuote.lstQuoteAccessories
					?.filter((accessory) => accessory.selected === true)
					.map((accessory) => ({
						model: accessories.find((acc) => acc.id === accessory.accessoryId)
							? accessories.find((acc) => acc.id === accessory.accessoryId).name
							: "accessory",
						static1: "",
						static2: "",
						quantity: accessory.quantity.toString(),
					})),
			],
		};
		let action =
			type === "download"
				? generateServiceTicket(data)
				: printServiceTicket(data);
		try {
			setBusy(true);
			await dispatch(action);
		} finally {
			setBusy(false);
		}
	};

	const invoice = async (type) => {
		let data = {
			shipping: {
				// customerName: draftQuote.quoteDetails.customeerName,
				customerName:
					(draftQuote.quoteDetails.shippingContact?.firstName != null &&
						draftQuote.quoteDetails.shippingContact?.firstName != undefined
						? draftQuote.quoteDetails.shippingContact?.firstName
						: "") +
					" " +
					(draftQuote.quoteDetails.shippingContact?.lastName != null &&
						draftQuote.quoteDetails.shippingContact?.lastName != undefined
						? draftQuote.quoteDetails.shippingContact?.lastName
						: ""),
				companyName: draftQuote.quoteDetails.customerName,
				address1: draftQuote.quoteDetails.shippingAddress?.addressLine1,
				city: draftQuote.quoteDetails.shippingAddress?.city,
				zipCode: draftQuote.quoteDetails.shippingAddress?.zipCode,
				address2: draftQuote.quoteDetails.shippingAddress?.addressLine2,
				phone: draftQuote.quoteDetails.shippingContact?.phone,
				email: draftQuote.quoteDetails.shippingContact?.email,
			},
			billing: {
				// customerName: draftQuote.quoteDetails.customerName,
				customerName:
					(draftQuote.quoteDetails.billingContact?.firstName != null &&
						draftQuote.quoteDetails.billingContact?.firstName != undefined
						? draftQuote.quoteDetails.billingContact?.firstName
						: "") +
					" " +
					(draftQuote.quoteDetails.billingContact?.lastName != null &&
						draftQuote.quoteDetails.billingContact?.lastName != undefined
						? draftQuote.quoteDetails.billingContact?.lastName
						: ""),
				companyName: draftQuote.quoteDetails.customerName,
				address1: draftQuote.quoteDetails.billingAddress?.addressLine1,
				address2: draftQuote.quoteDetails.billingAddress?.addressLine2,
				city: draftQuote.quoteDetails.billingAddress?.city,
				zipCode: draftQuote.quoteDetails.billingAddress?.zipCode,
				phone: draftQuote.quoteDetails.billingContact?.phone,
				email: draftQuote.quoteDetails.billingContact?.email,
			},
			innvoiveTable: [
				...draftQuote.lstQuoteEquipments?.map((equipment) => ({
					date: new Date().toLocaleDateString(),
					equipmentCategory: products.find(
						(p) => p.id === equipment.equipmentId
					).equipmentCategoryName,
					model: products.find((p) => p.id === equipment.equipmentId)
						.modelOutputname,
					electrical: null,
					sizing: null,
					quantity: equipment.quantity + "",
					price: "$ " + formatMoney(equipment.price?.toFixed(2)),
					total: "$ " + formatMoney(equipment.total?.toFixed(2)),
				})),
				...draftQuote.lstQuoteAccessories?.map((accessory) => ({
					date: new Date().toLocaleDateString(),
					model: accessories.find((acc) => acc.id === accessory.accessoryId)
						? accessories.find((acc) => acc.id === accessory.accessoryId).name
						: "accessory",
					electrical: null,
					sizing: null,
					quantity: accessory.quantity + "",
					price: "$ " + formatMoney(accessory.price?.toFixed(2)),
					total: "$ " + formatMoney(accessory.total?.toFixed(2)),
				})),
			],
			subTotal: "$ " + formatMoney(draftQuote.quoteDetails.subTotal.toFixed(2)),
			salesTax:
				"$ " + formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
			ccFee:
				"$ " + formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
			shippingFee:
				"$ " + formatMoney(draftQuote.quoteDetails.shippingTotal.toFixed(2)),
			total: "$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
		};
		let action =
			type === "download"
				? generateInvoiceTemplate(data)
				: printInvoiceTicket(data);
		try {
			setBusy(true);
			await dispatch(action);
		} finally {
			setBusy(false);
		}
	};
	const [open, setOpen] = useState(true);

	return (
		<>
			<div className="card mt-1" id="TicketGenerationCard">
				<div
					className="card-body d-flex justify-content-between pb-0"
					style={{ cursor: "pointer" }}
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					<div>
						<h4>Generate Tickets</h4>
						<p>Click button to generate ticket</p>
					</div>
					<div className="text-right ml-5">
						<i class="fas fa-caret-down fa-lg"></i>
					</div>
				</div>
				<Collapse in={open}>
					<div className="card-body ">
						<div className="form">
							<section className="row">
								<div className="col-md-12">
									{/* <ButtonGroup className="mr-2"> */}
									<Button
										onClick={(e) => {
											setReservationCardShow(true);
										}}
										variant="secondary"
										className="mr-2"
									>
										Reservation
									</Button>
									{ReservationCardShow && (
										<Reservation
											ReservationCardShow={ReservationCardShow}
											setReservationCardShow={setReservationCardShow}
										/>
									)}
									{/* <DropdownButton as={ButtonGroup} variant="secondary">
                                        <DropdownItem onClick={(e) => { reservationTicket('print') }}>Print</DropdownItem>
                                    </DropdownButton> */}
									{/* </ButtonGroup> */}
									{/* <ButtonGroup className="mr-2"> */}
									<Button
										onClick={(e) => {
											setDeliveryCard(true);
										}}
										variant="secondary"
										className="mr-2"
									>
										Delivery
									</Button>
									{DeliveryCard && (
										<Delivery
											DeliveryCard={DeliveryCard}
											setDeliveryCard={setDeliveryCard}
										/>
									)}
									{/* <DropdownButton as={ButtonGroup} variant="secondary">
                                        <DropdownItem onClick={(e) => { deliveryTicket('print') }}>Print</DropdownItem>
                                    </DropdownButton> */}
									{/* </ButtonGroup> */}
									{/* <ButtonGroup className="mr-2"> */}
									<Button
										onClick={(e) => {
											setSwapoutCard(true);
										}}
										variant="secondary"
										className="mr-2"
									>
										Swap Out
									</Button>
									{SwapoutCard && (
										<Swapout
											SwapoutCard={SwapoutCard}
											setSwapoutCard={setSwapoutCard}
										/>
									)}
									{/* <DropdownButton as={ButtonGroup} variant="secondary">
                                        <DropdownItem onClick={(e) => { swapoutTicket('print') }}>Print</DropdownItem>
                                    </DropdownButton> */}
									{/* </ButtonGroup> */}
									{/* <ButtonGroup className="mr-2"> */}
									<Button
										onClick={(e) => {
											setShowPickup(true);
										}}
										variant="secondary"
										className="mr-2"
									>
										Pick Up
									</Button>
									{showPickup && (
										<Pickup
											showPickup={showPickup}
											setShowPickup={setShowPickup}
										/>
									)}
									{/* <DropdownButton as={ButtonGroup} variant="secondary">
                                        <DropdownItem onClick={(e) => { pickupTicket('print') }}>Print</DropdownItem>
                                    </DropdownButton> */}
									{/* </ButtonGroup> */}
									<Button
										onClick={(e) => {
											setService(true);
										}}
										variant="secondary"
										className="mr-2"
									>
										Service
									</Button>
									{ServiceCard && (
										<Service
											setService={setService}
											ServiceCard={ServiceCard}
										/>
									)}
									{/* <DropdownButton as={ButtonGroup} variant="secondary">
                                        <DropdownItem onClick={(e) => { serviceTicket('print') }}>Print</DropdownItem>
                                    </DropdownButton> */}
									<Button
										onClick={(e) => {
											setShowInvoice(true);
										}}
										variant="secondary"
										className="mr-2"
									>
										Invoice
									</Button>
									{showInvoice && (
										<Invoice
											showInvoice={showInvoice}
											setShowInvoice={setShowInvoice}
										/>
									)}
									{/* <DropdownButton as={ButtonGroup} variant="secondary">
                                        <DropdownItem onClick={(e) => { invoice('print') }}>Print</DropdownItem>
                                    </DropdownButton> */}
								</div>
							</section>
						</div>
					</div>
				</Collapse>
			</div>
		</>
	);
};

export default TicketsUtilityCard;

const formatMoney = (number, decPlaces, decSep, thouSep) => {
	var decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
		decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	var sign = number < 0 ? "-" : "";
	var i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
	);
	var j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces
			? decSep +
			Math.abs(number - i)
				.toFixed(decPlaces)
				.slice(2)
			: "")
	);
};
