import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import UploadDocumentModal from "./UploadDocumentModal";
import { Collapse } from "react-bootstrap";
import { withLoader } from "../../utils/hoc/withLoader";

import {
	getAllQuoteDocuments,
	downloadDocument,
	printInvoiceTicket,
	generateInvoiceTemplate,
	generateZip,
	downloadZipFile,
	deleteDocument,
} from "../../data/reducers/quote.reducer";

const DocumentCard = ({ quoteId, setBusy }) => {
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const dispatch = useDispatch();
	const quoteDocuments = useSelector(
		(state) => state.quoteReducer
	).quoteDocuments;

	const [documentList, setDocumentList] = useState([]);

	const fetchQuoteDocuments = async () => {
		await dispatch(getAllQuoteDocuments());
	};

	const download = async (doc) => {
		await dispatch(downloadDocument(doc));
	};

	const downloadZIPListener = async () => {
		await dispatch(downloadZipFile(draftQuote?.quoteDetails?.id));
	};

	const print = async (invoice) => {
		await dispatch(printInvoiceTicket(invoice));
	};

	useEffect(() => {
		let docs = quoteDocuments.filter(
			(doc) => doc.quoteId === parseInt(quoteId)
		);
		if (docs) {
			setDocumentList(docs);
		}
	}, [quoteDocuments, quoteId]);

	useEffect(() => {
		fetchQuoteDocuments();
	}, []);

	const tableColumns = [
		{ name: "ID", selector: "id", maxWidth: "20px" },
		{ name: "Document Name", selector: "documentName" },
		{ name: "Created By", selector: "createdBy" },
		{
			name: "Actions",
			cell: (row) => (
				<div className="action-items">
					<button
						className="btn btn-outline btn-sm m-1"
						onClick={(e) => {
							e.preventDefault();
							download(row);
						}}
					>
						<i className="fas fa-download"></i>
					</button>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={() => {
							del(row.id);
						}}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];
	const del = async (id) => {
		try {
			setBusy(true);
			await dispatch(deleteDocument(id));
			await dispatch(getAllQuoteDocuments());
		} finally {
			setBusy(false);
		}
	};
	const [uploadModalVisible, setUploadModalVisible] = useState(false);
	const [open, setOpen] = useState(false);

	return (
		<div className="card mt-1 p-2"
			id="UploadDocumentScroll"	>
			<div
				className="card-title mt-2 d-flex justify-content-between"
				style={{ cursor: "pointer" }}
				onClick={() => setOpen(!open)}
				aria-controls="example-collapse-text"
				aria-expanded={open}
			>
				<h4 className="m-2">Document List</h4>
				<div className="text-right m-2 ml-5">
					<i class="fas fa-caret-down fa-lg"></i>
				</div>
			</div>
			<Collapse in={open}
			>
				<div className="card-body container-fluid" id="example-collapse-text">
					<div className="row">
						<div className="col-md-12">
							<DataTable
								noHeader={true}
								columns={tableColumns}
								data={documentList}
								pagination={true}
								paginationPerPage={100}
								paginationRowsPerPageOptions={[10, 50, 100, 200]}
							/>
							<br />
							<button
								className="btn btn-primary mr-3"
								onClick={(e) => {
									setUploadModalVisible(true);
								}}
							>
								Upload Document
							</button>
							<button
								className="btn btn-primary mr-3"
								onClick={() => {
									downloadZIPListener();
								}}
							>
								Download as a Zip
							</button>
							<button
								className="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									print();
								}}
							>
								Download
							</button>
						</div>
					</div>
					<UploadDocumentModal
						quoteId={quoteId}
						isVisible={uploadModalVisible}
						hideModal={(e) => {
							setUploadModalVisible(false);
						}}
					/>
				</div>
			</Collapse>
		</div>
	);
};

export default withLoader(DocumentCard);
