import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProductsAPI, updateProductAPI, createProductAPI, downloadProductDocumentAPI, updateQuoteEquipmentsAPI, deleteEquipmentAPI } from "../services/product.service";
import fileDownload from "js-file-download";

export const getAllProducts = createAsyncThunk(
    'productsX/getProducts',
    async (_, thunkAPI) => {
        const response = await getAllProductsAPI()
        if (response.isSuccessful) return response.data
        else thunkAPI.rejectWithValue(response.message)
    }
)

export const updateProduct = createAsyncThunk(
    'productsX/updateProduct',
    async (payload, thunkAPI) => {
        const response = await updateProductAPI(payload);
        if (response.isSuccessful) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const createProduct = createAsyncThunk(
    'productsX/createProduct',
    async (payload, thunkAPI) => {
        const response = await createProductAPI(payload);
        if (response.isSuccessful) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const downloadProductDocument = createAsyncThunk(
    'productsX/downloadDocument',
    async (payload, thunkAPI) => {
        const response = await downloadProductDocumentAPI(payload);
        if (response.isSuccessful === true) {
            fileDownload(response.data, payload.cutSheetPath);
        } else {
            thunkAPI.rejectWithValue(response.message);
        }
    }
)

export const updateQuoteEquipments = createAsyncThunk(
    'product/updateQuoteEquipment',
    async (payload, thunkAPI) => {
        const response = await updateQuoteEquipmentsAPI(payload);
        if(response.isSuccessful)
            return response.data;
        else
            thunkAPI.rejectWithValue(response.message)
    }
)

export const deleteEquipment = createAsyncThunk(
    'product/deleteEquipment',
    async(payload, thunkAPI) => {
        const response = await deleteEquipmentAPI(payload.id);
        if(response.isSuccessful){
            payload.setDeleteProductModal(false)
            return response.data;
        }
        else{
            payload.setDeleteProductModal(true)
            thunkAPI.rejectWithValue(response.message)
        }
    }
)

const productsXSlice = createSlice({
    name: 'productsX',
    initialState: {
        products: undefined
    },
    reducers: {

    },
    extraReducers: {
        [getAllProducts.fulfilled]: (state, action) => {
            state.products = action.payload
        }
    }
})

export default productsXSlice.reducer