import React, { useEffect } from "react"
import { Route , Redirect } from "react-router-dom"

import DashboardRoutes from "./dashboard.routes"
import Login from "../pages/authentication/Login"

import { useSelector, useDispatch } from "react-redux"
import { checkLogin } from "../data/reducers/authx.reducer"

const PrimaryRoutes = () => {

    const dispatch = useDispatch()
    const { loggedIn } = useSelector(state => state.authXReducer)

    useEffect(() => {
        dispatch(checkLogin())
    }, [loggedIn])

    return ((loggedIn) ? <Route><DashboardRoutes/></Route> : <Route><Login/></Route>)
}


export default PrimaryRoutes