import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { createCustomer } from "../../data/reducers/customerx.reducer";
import AddAddressContact from "./AddAddressContact";
import queryString from "query-string";

const AddCustomer = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const inputRef = useRef();
	const shouldGoBack = queryString.parse(location.search).goBack;
	const [customer, setCustomer] = useState({
		customerDetails: {},
		contactAndAddressDetails: { isDefaultBilling: true },
	});
	const [shippingDetails, setShippingDetails] = useState({
		firstName: "",
		lastName: "",
		addressLine1: "",
		addressLine2: "",
		zipcode: "",
		notes: "",
		city: "",
		state: "",
		phone: "",
		isDefaultShipping: true,
	});
	const [shippingAddress, setShippingAddress] = useState(true);
	const [custmorId, setCustmorId] = useState("");
	const showShippingDetailsListener = () => {
		setShippingAddress(!shippingAddress);
	};
	useEffect(() => {
		inputRef.current.focus();
	}, []);
	const create = async (e) => {
		try {
			e.preventDefault();
			if (shippingAddress) {
				let response = await dispatch(
					createCustomer({
						customerDetails: customer.customerDetails,
						contactAndAddressDetails: [
							{
								...customer.contactAndAddressDetails,
								isDefaultBilling: true,
								isDefaultShipping: true,
							},
						],
					})
				);

				if (response.error) {
					console.log(response.error);
				} else {
					if (shouldGoBack) {
						history.goBack();
					} else {
						history.push("/customers");
					}
				}
			} else {
				let response = await dispatch(
					createCustomer({
						customerDetails: customer.customerDetails,
						contactAndAddressDetails: [
							customer.contactAndAddressDetails,
							shippingDetails,
						],
					})
				);

				if (response.error) {
					console.log(response.error);
				} else {
					if (shouldGoBack) {
						history.goBack();
					} else {
						history.push("/customers");
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	};
	const createQuote = async (e) => {
		try {
			e.preventDefault();
			if (shippingAddress) {
				let response = await dispatch(
					createCustomer({
						customerDetails: customer.customerDetails,
						contactAndAddressDetails: [
							{
								...customer.contactAndAddressDetails,
								isDefaultBilling: true,
								isDefaultShipping: true,
							},
						],
					})
				);
				console.log("response", response);
				if (response.error) {
					console.log(response.error);
				} else {
					if (shouldGoBack) {
						history.goBack();
					} else {
						if (response.payload.customerDetails.id) {
							history.push(
								"/quote/add?customerId=" +
								response.payload.customerDetails.id
							);
							console.log(response.payload.customerDetails.id);
						}
					}
				}
			} else {
				let response = await dispatch(
					createCustomer({
						customerDetails: customer.customerDetails,
						contactAndAddressDetails: [
							customer.contactAndAddressDetails,
							shippingDetails,
						],
					})
				);

				if (response.error) {
					console.log(response.error);
				} else {
					if (shouldGoBack) {
						history.goBack();
					} else {
						if (response.payload.customerDetails.id) {
							history.push(
								"/quote/add?customerId=" +
								response.payload.customerDetails.id
							);
							console.log(response.payload.customerDetails.id);
						}
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/customers">Customers</Link>
				</li>
				<li className="breadcrumb-item active">Add New Customer</li>
			</ol>
			<div className="row">
				<div className="col-md-12">
					<form className="form" onSubmit={create}>
						<h2>Add New Customer</h2>
						<div>Fill up the form to create a new product listing</div>
						<br />
						<div className="card">
							<div className="card-body">
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<label>Customer Name</label>
											<input
												ref={inputRef}
												required
												className="form-control"
												placeholder="Customer Name"
												onChange={(e) => {
													setCustomer({
														...customer,
														customerDetails: {
															...customer.customerDetails,
															name: e.target.value,
														},
													});
												}}
											></input>
										</div>
									</div>
									{/* <div className="col-md-12">
									<button
										className="btn btn-primary"
										onClick={(e) => {
											e.preventDefault();
											console.log("creating customer...");
											create();
										}}
									>
										Save
									</button>
									&nbsp;
									<button
										className="btn btn-danger"
										onClick={(e) => {
											e.preventDefault();
											history.goBack();
										}}
									>
										Discard
									</button>
								</div> */}
								</div>
							</div>

						</div>
						{/* <AddAddressContact /> */}
						<div className="card mt-4">
							<div className="card-body">
								<h4>Customer Contact and Address Details</h4>
								{!shippingAddress && <h4>BILLING DETAILS</h4>}
								<p>Basic Details</p>
								<div>
									<div className="row">
										<div className="col-md-6">
											<label>First Name</label>
											<input
												required
												className="form-control"
												placeholder="First Name"
												type="text"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															firstName: e.target.value,
														},
													});
												}}
											/>
											<br />
										</div>
										<div className="col-md-6">
											<label>Last Name</label>
											<input
												required
												className="form-control"
												placeholder="Last Name"
												type="text"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															lastName: e.target.value,
														},
													});
												}}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<label>Phone</label>
											<input
												required
												className="form-control"
												placeholder="Phone"
												type="number"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															phone: e.target.value,
														},
													});
												}}
											/>
											<br />
										</div>
										<div className="col-md-6">
											<label>Email</label>
											<input
												required
												className="form-control"
												placeholder="Email"
												type="email"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															email: e.target.value,
														},
													});
												}}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12">
											<p>Address Details</p>
										</div>
										<div className="col-md-6">
											<label>Address Line 1</label>
											<input
												required
												className="form-control"
												placeholder="Address"
												type="text"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															addressLine1: e.target.value,
														},
													});
												}}
											/>
											<br />
										</div>
										<div className="col-md-6">
											<label className="mt-0">Address Line 2</label>
											<input
												className="form-control"
												placeholder="Address"
												type="text"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															addressLine2: e.target.value,
														},
													});
												}}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-md-4">
											<label>City</label>
											<input
												required
												className="form-control"
												placeholder="City"
												type="text"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															city: e.target.value,
														},
													});
												}}
											/>
											<br />
										</div>
										<div className="col-md-4">
											<label >State</label>
											<input
												className="form-control"
												placeholder="State"
												type="text"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															state: e.target.value,
														},
													});
												}}
											/>
										</div>
										<div className="col-md-4">
											<label>Zip Code</label>
											<input
												required
												className="form-control"
												placeholder="Zip Code"
												type="number"
												onChange={(e) => {
													setCustomer({
														...customer,
														contactAndAddressDetails: {
															...customer.contactAndAddressDetails,
															zipcode: e.target.value,
														},
													});
												}}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<label>Note</label>
										<input
											className="form-control"
											placeholder="Note"
											type="text"
											onChange={(e) => {
												setCustomer({
													...customer,
													contactAndAddressDetails: {
														...customer.contactAndAddressDetails,
														notes: e.target.value,
													},
												});
											}}
										/>
									</div>
									<div className="col-md-12">
										<div className="m-4 pt-0">
											<input
												readOnly
												checked={shippingAddress}
												onClick={() => {
													showShippingDetailsListener();
												}}
												type="checkbox"
												className="form-check-input"
												id="exampleCheck1"
											/>
											<label class="form-check-label" htmlFor="exampleCheck1">
												Shipping and Billing are same
											</label>
										</div>
									</div>

								</div>
							</div>
						</div>

						{!shippingAddress && (
							<div className="card mt-4">
								<div className="card-body">
									<h4>SHIPPING DETAILS</h4>
									<p>Basic Details</p>
									<div className="row">
										<div className="col-md-6 mt-1">
											<label>First Name</label>
											<input
												required
												className="form-control"
												placeholder="First Name"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														firstName: e.target.value,
													});
												}}
											/>
										</div>
										<div className="col-md-6 mt-1">
											<label>Last Name</label>
											<input
												required
												className="form-control"
												placeholder="Last Name"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														lastName: e.target.value,
													});
												}}
											/>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-md-6 mt-1">
											<label>Phone</label>
											<input
												required
												className="form-control"
												placeholder="Phone"
												type="number"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														phone: e.target.value,
													});
												}}
											/>

										</div>
										<div className="col-md-6 mt-1">
											<label>Email</label>
											<input
												required
												className="form-control"
												placeholder="Email"
												type="email"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														email: e.target.value,
													});
												}}
											/>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-md-12">
											<p>Address Details</p>
										</div>
										<div className="col-md-6 mt-1">
											<label>Address Line 1</label>
											<input
												required
												className="form-control"
												placeholder="Address"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														addressLine1: e.target.value,
													});
												}}
											/>
										</div>
										<div className="col-md-6 mt-1">
											<label className="mt-0">Address Line 2</label>
											<input
												className="form-control"
												placeholder="Address"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														addressLine2: e.target.value,
													});
												}}
											/>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-md-4 mt-1">
											<label>City</label>
											<input
												required
												className="form-control"
												placeholder="City"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														city: e.target.value,
													});
												}}
											/>
										</div>
										<div className="col-md-4 mt-1">
											<label >State</label>
											<input
												required
												className="form-control"
												placeholder="State"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														state: e.target.value,
													});
												}}
											/>
										</div>
										<div className="col-md-4 mt-1">
											<label>Zip Code</label>
											<input
												required
												className="form-control"
												placeholder="Zip Code"
												type="number"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														zipcode: e.target.value,
													});
												}}
											/>
										</div>
									</div>
									<br />
									<div className="row">
										<div className="col-md-12">
											<label>Note</label>
											<input
												className="form-control"
												placeholder="Note"
												type="text"
												onChange={(e) => {
													setShippingDetails({
														...shippingDetails,
														notes: e.target.value,
													});
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="mt-3">
							<button
								className="btn btn-primary mr-2"
								onClick={(e) => {
									createQuote(e);
								}}
							>
								Save And Create Quote
							</button>

							<button
								className="btn btn-primary mr-2"
							// onClick={(e) => {
							// 	e.preventDefault();
							// 	console.log("creating address and contact...");
							// 	create();
							// }}
							>
								Save
							</button>

							<button
								className="btn btn-danger"
								onClick={(e) => {
									e.preventDefault();
									history.goBack();
								}}
							>
								Delete Customer
							</button>
						</div>
					</form>

					{/* <form>
						<Row className="mb-3">
							<Form.Group as={Col} controlId="formGridEmail">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" placeholder="Enter email" />
							</Form.Group>

							<Form.Group as={Col} controlId="formGridPassword">
								<Form.Label>Password</Form.Label>
								<Form.Control type="password" placeholder="Password" />
							</Form.Group>
						</Row>

						<Form.Group className="mb-3" controlId="formGridAddress1">
							<Form.Label>Address</Form.Label>
							<Form.Control placeholder="1234 Main St" />
						</Form.Group>

						<Form.Group className="mb-3" controlId="formGridAddress2">
							<Form.Label>Address 2</Form.Label>
							<Form.Control placeholder="Apartment, studio, or floor" />
						</Form.Group>

						<Row className="mb-3">
							<Form.Group as={Col} controlId="formGridCity">
								<Form.Label>City</Form.Label>
								<Form.Control />
							</Form.Group>

						

							<Form.Group as={Col} controlId="formGridZip">
								<Form.Label>Zip</Form.Label>
								<Form.Control />
							</Form.Group>
						</Row>

						<Form.Group className="mb-3" id="formGridCheckbox">
							<Form.Check type="checkbox" label="Check me out" />
						</Form.Group>

						<Button variant="primary" type="submit">
							Submit
						</Button>
					</form> */}
				</div>
			</div>
		</>
	);
};

export default AddCustomer;
