import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDropdowns } from "../../data/reducers/configurationx-data.reducer";
import {
	setRentalTimeFrame,
	setTermsOfPayments,
} from "../../data/reducers/quote.reducer";
import { Collapse } from "react-bootstrap";

const TermsCard = () => {
	const dispatch = useDispatch();

	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const { dropdowns } = useSelector((state) => state.configurationXReducer);
	const rentalTimeFrames = dropdowns?.rentalTimeFrames;
	const termsOfPayment = dropdowns?.termsOfPayments;

	const fetchDropdowns = async () => {
		if (dropdowns === undefined) {
			try {
				dispatch(getDropdowns());
			} catch (e) {
				console.log(e);
			}
		}
	};

	useEffect(() => {
		fetchDropdowns();
	}, []);

	// set default values for
	// rental time frame and terms of payment
	useEffect(() => {
		// rental time frame
		if (
			rentalTimeFrames !== undefined &&
			draftQuote?.quoteDetails?.rentalTimeFrameId === undefined
		) {
			dispatch(setRentalTimeFrame(rentalTimeFrames[0]));
		} else if (
			rentalTimeFrames !== undefined &&
			draftQuote?.quoteDetails?.rentalTimeFrameId > 0 &&
			draftQuote?.quoteDetails?.rentalTimeFrame === undefined
		) {
			let selectedRentalTimeFrame = rentalTimeFrames.find(
				(timeframe) =>
					timeframe.id === draftQuote?.quoteDetails?.rentalTimeFrameId
			);
			dispatch(setRentalTimeFrame(selectedRentalTimeFrame));
		}

		// terms of payment
		if (
			termsOfPayment !== undefined &&
			draftQuote?.quoteDetails?.termsOfPaymentId === undefined
		) {
			dispatch(setTermsOfPayments(termsOfPayment[0]));
		} else if (
			termsOfPayment !== undefined &&
			draftQuote?.quoteDetails?.termsOfPaymentId > 0 &&
			draftQuote?.quoteDetails.termsOfPayment === undefined
		) {
			let selectedTermsOfPayment = termsOfPayment.find(
				(terms) => terms.id === draftQuote?.quoteDetails?.termsOfPaymentId
			);
			dispatch(setTermsOfPayments(selectedTermsOfPayment));
		}
	}, [draftQuote, dispatch, rentalTimeFrames, termsOfPayment]);
	const [open, setOpen] = useState(true);

	return (
		<>
			<div className="card mt-1 pb-2">
				<div
					className="card-body p-0 mt-2 ml-2 d-flex justify-content-between"
					style={{ cursor: "pointer" }}
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					{/* <h3 className="ml-2">Terms</h3>
					<div className="text-right m-2 ml-5">
						<i class="fas fa-caret-down fa-lg"></i>
					</div> */}
				</div>
				<Collapse in={open}>
					<div className="card-body p-0 mt-0 ml-3">
						<div className="form">
							<section className="row">
								<div className="col-md-12 row">
									<div className="col-md-3">
										<div className="form-group d-flex flex-column">
											<label className="col-form-label col-form-label-sm">
												<b>Rental Time Frame</b>
											</label>
											<select
												className="form-control form-control-sm"
												onChange={(e) => {
													let rentalTerm = rentalTimeFrames.find(
														(rentalTimeFrame) =>
															rentalTimeFrame.id === parseInt(e.target.value)
													);
													dispatch(setRentalTimeFrame(rentalTerm));
												}}
												value={draftQuote?.quoteDetails?.rentalTimeFrameId}
											>
												<option value="0" disabled>
													---Select---
												</option>
												{rentalTimeFrames?.map((rentalTimeFrame) => (
													<option
														key={rentalTimeFrame.id}
														value={rentalTimeFrame.id}
													>
														{rentalTimeFrame.rentalTimeFrame}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group d-flex flex-column">
											<label className="col-form-label col-form-label-sm">
												<b>Terms of Payment</b>
											</label>
											<select
												className="form-control form-control-sm"
												onChange={(e) => {
													let selectedTerms = termsOfPayment.find(
														(term) => term.id === parseInt(e.target.value)
													);
													dispatch(setTermsOfPayments(selectedTerms));
												}}
												value={draftQuote?.quoteDetails?.termsOfPaymentId}
											>
												<option value="0" disabled>
													---Select---
												</option>
												{termsOfPayment?.map((term) => (
													<option key={term.id} value={term.id}>
														{term.termsPayment}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</Collapse>
			</div>
		</>
	);
};

export default TermsCard;
