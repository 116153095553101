import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllNotes } from "../../data/reducers/notes.reducer";
import { Link } from "react-router-dom";
import {
	setShippingCost,
	setShippingNumber,
	setOtherCost,
	setOtherNumber,
	setSalesTax,
	toggleCCFee,
	setSpecialNotes,
} from "../../data/reducers/quote.reducer";
import SalesTaxModal from "./SalesTaxModal";
import { Collapse } from "react-bootstrap";

const QuotePriceCard = () => {
	const dispatch = useDispatch();
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	// console.log("draftQuotes", draftQuote);
	const salesTax = useSelector((state) => state.salesTaxReducer)?.salesTaxes;
	const { notes } = useSelector((state) => state.notesReducer);
	const onSelectNotes = (value) => {
		let newNote = draftQuote?.quoteDetails?.specialNotes + ", " + value;
		dispatch(setSpecialNotes(newNote))
	}
	useEffect(() => {
		if (notes === undefined) dispatch(getAllNotes());
	}, [notes]);
	// console.log("SpecialNotes", draftQuote?.quoteDetails?.specialNotes);
	useEffect(() => {
		if (salesTax?.length > 0) {
			if (draftQuote?.quoteDetails.salesTaxId) {
				let selectedTax = salesTax.find(
					(tax) => tax.id === draftQuote.quoteDetails.salesTaxId
				);
				dispatch(
					setSalesTax({
						taxId: selectedTax.id,
						taxVal: selectedTax.salesTaxVal,
					})
				);
			} else {
				let selectedTax = salesTax[0];
				dispatch(
					setSalesTax({
						taxId: selectedTax.id,
						taxVal: selectedTax.salesTaxVal,
					})
				);
			}
		}
	}, [salesTax, dispatch]);

	const [filteredNotes, setFilteredNotes] = React.useState(notes);

	const filterNotes = (value) => {
		let tempNotes = notes.filter((note) =>
			note?.note?.toLowerCase().includes(value.toLowerCase())
		);
		setFilteredNotes(tempNotes);
	};

	useEffect(() => {
		setFilteredNotes(notes);
	}, [notes]);

	const [salesTaxModalVisible, setSalesTaxModalVisible] = useState(false);
	const [open, setOpen] = useState(false);
	// console.log("filteredNotes", filteredNotes);
	return (
		<div>
			<SalesTaxModal
				isVisible={salesTaxModalVisible}
				hideModal={(e) => {
					setSalesTaxModalVisible(false);
				}}
			/>
			<div className="mt-1">
				{/* <div
					className="card-title mt-2 d-flex justify-content-between p-3"
					style={{ cursor: "pointer" }}
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					<div>
						<h3>Summary</h3>
						<p>Final Summary of Quote</p>{" "}
					</div>
					<div className="text-right ml-5">
						<i class="fas fa-caret-down fa-lg"></i>
					</div>
				</div> */}

				{/* <Collapse in={open}> */}
				<div className="card-body">
					<div className="form">
						<section className="row">
							<div className="offset-md-6 col-md-4 text-right">
								<b>Total of Equipment:</b>
							</div>
							<div className="col-md-2 text-right">
								${" "}
								{formatMoney(
									draftQuote?.quoteDetails?.totalEquipment?.toFixed(2)
								)}
							</div>
							{/* <div className="col-md-12">
								<hr />
							</div> */}
							<div className="col-md-6">
								<label className="col-form-label col-form-label-sm">
									Notes
								</label>
								<textarea
									className="form-control form-control-sm"
									rows="5"
									value={draftQuote?.quoteDetails?.specialNotes}
									onChange={(e) => {
										dispatch(setSpecialNotes(e.target.value));
									}}
								></textarea>
								<div className="input-group mt-3">
									<input
										className="form-control border-right-0"
										placeholder="Search Other Notes"
										onChange={(e) => {
											filterNotes(e.target.value);
										}}
									/>
									<span className="input-group-append bg-white border-left-0">
										<span className="input-group-text bg-transparent">
											<i class="fas fa-angle-down"></i>
										</span>
									</span>
								</div>
								<div
									className="mt-3"
									style={{ height: "15rem", overflowY: "scroll" }}
								>
									<ul className="list-group list-group-flush" >
										{filteredNotes?.map((note) => (
											<li
												key={note.id}
												className="d-inline-block text-truncate list-group-item"
												style={{ cursor: "pointer" }}
												onClick={() => {
													onSelectNotes(note?.note)
												}}
											>

												{note.note}

											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<div className="row">
									<div className="col-md-4 form-group">
										<label className="col-form-label col-form-label-sm">
											Cost of Shipping
										</label>
										<div className="input-group input-group-sm">
											<div className="input-group-prepend">
												<span className="input-group-text" id="input-group-sm">
													$
												</span>
											</div>
											<input
												type="number"
												className="form-control form-control-sm"
												placeholder="Shipping Cost"
												aria-describedby="input-group-sm"
												value={draftQuote?.quoteDetails?.shippingCost}
												onChange={(e) => {
													dispatch(setShippingCost(e.target.valueAsNumber));
												}}
											></input>
										</div>
									</div>
									<div className="col-md-4 form-group">
										<label className="col-form-label col-form-label-sm">
											# of Shipping
										</label>
										<div className="input-group input-group-sm">
											<input
												type="number"
												className="form-control form-control-sm"
												placeholder="Shipping Count"
												value={draftQuote?.quoteDetails?.shippingNumber}
												onChange={(e) => {
													dispatch(setShippingNumber(e.target.valueAsNumber));
												}}
											></input>
										</div>
									</div>
									<div className="offset-md-1 col-md-3 form-group text-right align-self-center">
										${" "}
										{formatMoney(
											draftQuote?.quoteDetails?.shippingTotal?.toFixed(2)
										)}
									</div>
								</div>
								<hr />
								<div className="row">
									<div className="col-md-4 form-group">
										<label className="col-form-label col-form-label-sm">
											Other Cost
										</label>
										<div className="input-group input-group-sm">
											<div className="input-group-prepend">
												<span className="input-group-text">$</span>
											</div>
											<input
												type="number"
												className="form-control form-control-sm"
												placeholder="Others"
												value={draftQuote?.quoteDetails?.otherCost}
												onChange={(e) => {
													dispatch(setOtherCost(e.target.valueAsNumber));
												}}
											></input>
										</div>
									</div>
									<div className="col-md-4 form-group">
										<label className="col-form-label col-form-label-sm">
											x n
										</label>
										<div className="input-group input-group-sm">
											<input
												type="number"
												className="form-control"
												placeholder="x n"
												value={draftQuote?.quoteDetails?.otherNumber}
												onChange={(e) => {
													dispatch(setOtherNumber(e.target.valueAsNumber));
												}}
											></input>
										</div>
									</div>
									<div className="offset-md-1 col-md-3 form-group text-right align-self-center">
										${" "}
										{formatMoney(
											draftQuote?.quoteDetails?.otherTotal?.toFixed(2)
										)}
									</div>
								</div>
								<hr />
								<div className="row">
									<div className="col-md-8 text-right">
										<b>Subtotal:</b>
									</div>
									<div className="offset-md-1 col-md-3 text-right">
										${" "}
										{formatMoney(
											draftQuote?.quoteDetails?.subTotal?.toFixed(2)
										)}
									</div>
								</div>
								<hr />

								<div className="row">
									<div className="col-md-4 form-group">
										<label className="col-form-label col-form-label-sm">
											Sales Tax
										</label>
									</div>
									<div className="col-md-4 form-group">
										<div className="d-flex flex-row">
											<select
												className="form-control form-control-sm"
												onChange={(e) => {
													let selectedTax = salesTax?.find(
														(tax) => tax.id === parseInt(e.target.value)
													);
													dispatch(
														setSalesTax({
															taxId: selectedTax.id,
															taxVal: selectedTax.salesTaxVal,
														})
													);
												}}
												value={draftQuote?.quoteDetails?.salesTaxId}
											>
												{salesTax?.map((tax) => (
													<option key={tax.id} value={tax.id}>
														{tax.salesTaxName}
													</option>
												))}
											</select>
											<div className="text-right">
												<button
													className="btn btn-sm btn-outline"
													onClick={(e) => {
														setSalesTaxModalVisible(true);
													}}
												>
													<i className="far fa-edit"></i>
												</button>
											</div>
										</div>

									</div>
									<div className="offset-md-1 col-md-3 text-right align-self-center">
										${" "}
										{formatMoney(
											draftQuote?.quoteDetails?.salesTaxTotal?.toFixed(2)
										)}
									</div>
								</div>
								{/* <div className="row">
									<div className="offset-md-4 col-md-4 form-group">
										<label className="col-form-label col-form-label-sm">
											Sales Tax
										</label>
										<div className="d-flex flex-row">
											<select
												className="form-control form-control-sm"
												onChange={(e) => {
													let selectedTax = salesTax?.find(
														(tax) => tax.id === parseInt(e.target.value)
													);
													dispatch(
														setSalesTax({
															taxId: selectedTax.id,
															taxVal: selectedTax.salesTaxVal,
														})
													);
												}}
												value={draftQuote?.quoteDetails?.salesTaxId}
											>
												{salesTax?.map((tax) => (
													<option key={tax.id} value={tax.id}>
														{tax.salesTaxName}
													</option>
												))}
											</select>
											<div className="text-right">
												<button
													className="btn btn-sm btn-outline"
													onClick={(e) => {
														setSalesTaxModalVisible(true);
													}}
												>
													<i className="far fa-edit"></i>
												</button>
											</div>
										</div>
									</div>
									<div className="offset-md-1 col-md-3 text-right align-self-center">
										${" "}
										{formatMoney(
											draftQuote?.quoteDetails?.salesTaxTotal?.toFixed(2)
										)}
									</div>
								</div> */}
								<hr />
								<div className="row">
									<div className="offset-md-4 col-md-4 d-flex flex-row align-items-center">
										<input
											type="checkbox"
											onChange={(e) => dispatch(toggleCCFee(e.target.checked))}
											checked={draftQuote.quoteDetails.ccFeeEnabled || false}
										/>
										&nbsp;&nbsp;
										<label
											className="col-form-label col-form-label-sm"
											style={{ paddingTop: "8px" }}
										>
											CC Fee
										</label>
									</div>
									<div className="offset-md-1 col-md-3 text-right align-self-center">
										${" "}
										{formatMoney(
											draftQuote?.quoteDetails?.creditCardFee?.toFixed(2)
										)}
									</div>
								</div>
								<hr />
								<div className="row">
									<div
										className="col-md-12 text-right"
										style={{ fontWeight: "bold", fontSize: "2rem" }}
									>
										${formatMoney(draftQuote?.quoteDetails?.totalQuote)}
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
				{/* </Collapse> */}
			</div>
		</div>
	);
};

export default QuotePriceCard;

const formatMoney = (number, decPlaces, decSep, thouSep) => {
	var decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
		decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	var sign = number < 0 ? "-" : "";
	var i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
	);
	var j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces
			? decSep +
			Math.abs(number - i)
				.toFixed(decPlaces)
				.slice(2)
			: "")
	);
};
