import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import DataTable from 'react-data-table-component'

import { getAllAccessories } from '../../data/actions/accessory.actions'

const ProductsDashboard = ({ accessoriesList, accessoryListLoading, getAllAccessories, setBusy }) => {

    const history = useHistory()
    useEffect(() => {
        if (accessoryListLoading === undefined || accessoryListLoading === false) {
            getAllAccessories()
        }
    })

    const [searchQuery, setSearchQuery] = useState('')

    const tableColumns = [
        { name: 'ID', selector: 'id', maxWidth: '20px' },
        { name: 'Name', grow: 2, selector: 'name', sortable: true },
        { name: 'Daily', cell: (row) => (<div>$ {row.daily}</div>) },
        { name: 'Weekend', cell: (row) => (<div>$ {row.weekend}</div>) },
        { name: 'Weekly', cell: (row) => (<div>$ {row.weekly}</div>) },
        { name: 'Monthly', cell: (row) => (<div>$ {row.monthly}</div>) },
        { name: 'Seasonal', cell: (row) => (<div>$ {row.seasonal}</div>) },
        { name: 'Actions', cell: (row) => (<div className='action-items'><Link to={'/accessories/edit?accessoryId=' + row.id} className='btn btn-outline btn-sm m-1'><i className='far fa-edit'></i></Link></div>) }
    ]

    const accessoriesData = (
        <div className="card" style={{ marginTop: '1rem' }}>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <h4>Accessories List</h4>
                    </div>
                    <div className="offset-md-4 col-md-2 text-right">
                        <input type='text' className='form-control' placeholder='Search' onChange={(e) => { setSearchQuery(e.target.value) }}></input>
                    </div>
                    <div className="col-md-12">
                        <DataTable
                            noHeader={true}
                            columns={tableColumns}
                            data={searchQuery == '' ? accessoriesList : accessoriesList.filter(accessory => accessory.name.toLowerCase().includes(searchQuery.toLowerCase()))}
                            pagination={true}
                            paginationPerPage={100}
                            paginationRowsPerPageOptions={[10, 50, 100, 200]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li className="breadcrumb-item active">Accessories</li>
            </ol>
            <div className='row'>
                <div className="col-md-8">
                    <h4>All Accessories</h4>
                </div>
                <div className="col-md-4 text-right">
                    <button className='btn btn-primary' onClick={() => { history.push("/accessories/add") }}>Add New Accessory</button>
                </div>
                <div className="col-md-12">
                    {accessoriesList !== undefined ? accessoriesData : <div>No accessories available</div>}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (globalState) => ({
    accessoriesList: globalState.accessoryReducer.accessories,
    accessoryListLoading: globalState.accessoryReducer.loading
})

const mapDispatchToProps = {
    getAllAccessories
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDashboard);