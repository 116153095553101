import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import DataTable from "react-data-table-component";

import {
	getAllProducts,
	downloadProductDocument,
	deleteEquipment,
} from "../../data/reducers/productx.reducer";
import DeleteProductModal from "./Modals/DeleteProductModal";
import { getQuotes } from "../../data/reducers/quote.reducer";
const ProductsDashboard = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [productList, setProductList] = useState([]);

	const products = useSelector((state) => state.productsXReducer).products;

	const [rowId, setRowId] = React.useState(null);
	const { quotes } = useSelector((state) => state.quoteReducer);

	// console.log('this si quotes', quotes)

	useEffect(() => {
		if (products) {
			setProductList(products);
		}
	}, [products]);

	useEffect(() => {
		dispatch(getAllProducts());
	}, []);

	const [searchQuery, setSearchQuery] = useState("");
	const [deleteProductModal, setDeleteProductModal] = useState(false);

	const tableColumns = [
		{ name: "ID", selector: "id", maxWidth: "20px" },
		{
			name: "Equipment Category",
			grow: 2,
			selector: "equipmentCategoryName",
			sortable: true,
		},
		{ name: "Output", selector: "modelOutputname" },
		{ name: "Property 1", selector: "staticProperty1" },
		{ name: "Property 2", selector: "staticProperty2" },
		{ name: "Daily", cell: (row) => <div>$ {row.daily}</div> },
		{ name: "Weekly", cell: (row) => <div>$ {row.weekly}</div> },
		{ name: "Monthly", cell: (row) => <div>$ {row.monthly}</div> },
		{
			name: "Cut Sheet",
			cell: (row) =>
				row.cutSheetName ? (
					<button
						className="btn btn-sm btn-link"
						style={{ padding: "0" }}
						onClick={(e) => {
							e.preventDefault();
							dispatch(
								downloadProductDocument({
									equipmentId: row.id,
									fileName: row.cutSheetName,
									cutSheetPath: row.cutSheetPath,
								})
							);
						}}
					>
						Download
					</button>
				) : (
					""
				),
		},
		{
			name: "Actions",
			cell: (row) => (
				<div className="action-items">
					<Link
						style={{ padding: "0" }}
						to={"/products/edit?productId=" + row.id}
						className="btn btn-outline btn-sm m-1"
					>
						<i className="far fa-edit"></i>
					</Link>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={(e) => {
							onDeleteIconClickListener(row.id);
						}}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];

	const onDeleteIconClickListener = async (id) => {
		await dispatch(deleteEquipment({ id: id, setDeleteProductModal }));
		await dispatch(getAllProducts());
		// setDeleteProductModal(true);
		setRowId(id);
	};

	const productData = (
		<div className="card" style={{ marginTop: "1rem" }}>
			<div className="card-body">
				<div className="row">
					<div className="col-md-6">
						<h4>Equipment List</h4>
					</div>
					<div className="offset-md-4 col-md-2 text-right">
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={(e) => {
								setSearchQuery(e.target.value);
							}}
						></input>
					</div>
					<div className="col-md-12">
						<DataTable
							noHeader={true}
							columns={tableColumns}
							data={
								searchQuery === ""
									? productList
									: productList.filter((product) => {
											return (
												product.equipmentCategoryName
													.toLowerCase()
													.includes(searchQuery.toLowerCase()) ||
												product.modelOutputname
													.toLowerCase()
													.includes(searchQuery.toLowerCase())
											);
									  })
							}
							pagination={true}
							selectableRows
							paginationPerPage={100}
							paginationRowsPerPageOptions={[10, 50, 100, 200]}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item active">Equipment</li>
			</ol>
			<div className="row">
				<div className="col-md-8">
					<h4>All Equipment</h4>
				</div>
				<div className="col-md-4 text-right">
					<button
						className="btn btn-primary"
						onClick={() => {
							history.push("/products/add");
						}}
					>
						Add New Product
					</button>
				</div>
				<div className="col-md-12">
					{productList !== undefined ? (
						productData
					) : (
						<div>No equipment available</div>
					)}
				</div>
			</div>
			{setDeleteProductModal && (
				<DeleteProductModal
					rowId={rowId}
					modalTitle="Action"
					isVisible={deleteProductModal}
					onSubmit={(value) => {
						setDeleteProductModal(false);
					}}
					onCancel={() => {
						setDeleteProductModal(false);
					}}
				/>
			)}
		</>
	);
};

export default ProductsDashboard;
